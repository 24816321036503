import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useEffect } from 'react';
import { Button } from './Button';
import { IoCloseOutline } from '@react-icons/all-files/io5/IoCloseOutline';
import { classNames } from 'utils/classNames';

const SIZES = {
  sm: 'w-3/5 lg:w-1/4',
  md: 'md:w-[1/3] lg:w-1/3',
  lg: 'w-full max-w-[700px]',
};
export interface ModalProps {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  // NOTE: title and description are required even for
  // minimal theme because screen readers use them
  title: string | JSX.Element;
  description?: ReactNode;
  body?: ReactNode | undefined;
  footer?: ReactNode | undefined;
  modalClasses?: string;
  width?: number | undefined;
  size?: 'sm' | 'md' | 'lg';
  theme?: 'default' | 'minimal';
}
export function Modal({
  isOpen,
  onClose,
  title,
  description,
  body,
  footer,
  size = 'md',
  width,
  theme = 'default',
}: ModalProps) {
  const _size = SIZES[size];

  useEffect(() => {
    if (isOpen) {
      document.querySelector('#app-root')?.classList.add('blur-none');
    }
    return () => {
      document.querySelector('#app-root')?.classList.remove('blur-none');
    };
  }, [isOpen]);

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-30 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black dark:bg-gray-800 opacity-80" />
          <div
            className={`relative bg-white dark:bg-black text-black ${_size} mx-auto rounded-xl border dark:border-gray-333`}
            style={width ? { width: width } : {}}
          >
            <div className="p-6">
              <div className="flex items-center">
                <Dialog.Title className={classNames(theme === 'minimal' && 'sr-only', 'font-bold text-2xl')}>
                  {title}
                </Dialog.Title>
                <Button
                  shape="rounded"
                  kind="text-only-light"
                  aria-label="Dismiss"
                  className={classNames('ml-auto', theme === 'minimal' && 'pt-2 pr-2')}
                  onClick={() => onClose(false)}
                >
                  <IoCloseOutline className="h-6 w-6 text-gray hover:text-black" />
                </Button>
              </div>
              {description && (
                <Dialog.Description as="div" className={classNames(theme !== 'minimal' && 'pl-0 mt-4')}>
                  {description}
                </Dialog.Description>
              )}
            </div>
            {body && <div className={classNames(theme !== 'minimal' && 'px-6 py-2')}>{body}</div>}
            {footer && theme !== 'minimal' && <div className="p-6">{footer}</div>}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
