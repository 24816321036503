import { RouteLink } from 'components/core/RouteLink';
import { Logo } from 'components/core/Logo';
import { OpenfundContext } from 'contexts/Openfund';
import { useContext, useEffect, useRef, useState } from 'react';
import { Routes } from 'RoutePaths';
import { IoWalletOutline } from '@react-icons/all-files/io5/IoWalletOutline';
import { IoPlanetOutline } from '@react-icons/all-files/io5/IoPlanetOutline';
import { IoNewspaperOutline } from '@react-icons/all-files/io5/IoNewspaperOutline';
import { IoStatsChartOutline } from '@react-icons/all-files/io5/IoStatsChartOutline';
import { IoNotificationsOutline } from '@react-icons/all-files/io5/IoNotificationsOutline';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { openfund } from 'services';
import { getErrorMsg } from 'utils/getErrorMsg';
import { classNames } from 'utils/classNames';
import { NavLink, NavLinkProps, useMatch, useResolvedPath } from 'react-router-dom';

export function FooterNavLink({ className, ...props }: NavLinkProps) {
  const resolved = useResolvedPath(props.to);
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    <NavLink
      {...props}
      className={classNames(match ? 'text-cyan' : 'text-gray', typeof className === 'string' && className)}
      aria-current={match ? 'page' : undefined}
    >
      {props.children}
    </NavLink>
  );
}

export function SiteFooter() {
  const { currentUser } = useContext(OpenfundContext);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const isMounted = useIsMounted();
  const toast = useToast();
  const mobileNavRef = useRef<HTMLElement>(null);
  const showOnePager = false; // TDOO: set to true when new article arrives

  useEffect(() => {
    if (currentUser && mobileNavRef.current && window.getComputedStyle(mobileNavRef.current).display !== 'none') {
      openfund
        .getNotifications(currentUser?.PublicKeyBase58Check, 0, 30, [])
        .then((res) => {
          if (isMounted) {
            setNotificationsCount(res.NotificationCounts.UnreadCount);
          }
        })
        .catch((e) => {
          toast.show({ message: getErrorMsg(e), type: 'error' });
        });
    }
  }, [currentUser]);

  return (
    <div className="pb-16 lg:pb-0">
      <nav
        ref={mobileNavRef}
        className="lg:hidden z-50 fixed bottom-0 border-t border-gray bg-gray-16 w-full flex items-center justify-between h-16 py-[40px] px-[20px]"
      >
        <FooterNavLink
          className="text-center"
          to={Routes.activity()}
          onClick={() => {
            // TODO: ideally we can also refresh the feed
            document.getElementById('site-header')?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          <IoNewspaperOutline className="inline-block text-2xl" />
          <p className="text-sm font-bold pt-2">Feed</p>
        </FooterNavLink>
        <FooterNavLink className="text-center" to={Routes.discover()}>
          <IoPlanetOutline className="inline-block text-2xl" />
          <p className="text-sm font-bold pt-2">Discover</p>
        </FooterNavLink>
        <FooterNavLink className="text-center" to={Routes.trade()}>
          <IoStatsChartOutline className="inline-block text-2xl" />
          <p className="text-sm font-bold pt-2">Trade</p>
        </FooterNavLink>
        {!!currentUser && (
          <>
            <FooterNavLink className="text-center" to={Routes.wallet()}>
              <IoWalletOutline className="inline-block text-2xl" />
              <p className="text-sm font-bold pt-2">Wallet</p>
            </FooterNavLink>
            <FooterNavLink className="text-center" to={Routes.notifications()}>
              <IoNotificationsOutline className={classNames('inline text-2xl', notificationsCount > 0 && 'text-red')} />
              <p className="text-sm font-bold pt-2">Notifications</p>
            </FooterNavLink>
          </>
        )}
      </nav>
      <footer className="pt-8 mt-24 px-162">
        <div className="max-w-7xl m-auto flex flex-col lg:flex-row px-6 justify-between relative z-10 text-white border-t pt-12 border-gray-333">
          <div className="flex justify-between">
            <div>
              <Logo className="mb-3" />
              <p className="font-light mb-2 text-xl leading-8 text-gray">
                We're currently in public beta as we
                <br />
                build the future of online communities.
              </p>
              <p className="font-light text-sm text-gray">
                &copy; Openfund 2022 •{' '}
                <a
                  className="text-gray underline"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.google.com/document/d/1QF0ofugvUA9vwMfP3Aal7SjMigdqMktSpzCrPidOOHU"
                >
                  Terms &amp; Conditions
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-col mt-12 lg:mt-0 lg:flex-row justify-between lg:text-right">
            <ul className="mr-20 leading-8 mb-12 lg:mb-0">
              <h4 className="text-white font-bold text-sm mb-2 uppercase">Explore</h4>
              <li>
                <RouteLink kind="text-only" to={Routes.home()}>
                  Home
                </RouteLink>
              </li>
              <li>
                <RouteLink kind="text-only" to={Routes.discover()}>
                  Discover
                </RouteLink>
              </li>
              <li>
                <RouteLink kind="text-only" to={Routes.activity()}>
                  Feed
                </RouteLink>
              </li>
              <li>
                <RouteLink kind="text-only" to={Routes.trade()}>
                  <span className="text-gray">Trade</span>
                </RouteLink>
              </li>
            </ul>
            <ul className="mr-20 leading-8 mb-12 lg:mb-0">
              <h4 className="text-white font-bold text-sm mb-2 uppercase">Manage</h4>
              <li>
                <RouteLink kind="text-only" to={Routes.wallet()}>
                  Wallet
                </RouteLink>
              </li>
              <li>
                <RouteLink kind="text-only" to={Routes.orders()}>
                  Orders
                </RouteLink>
              </li>
              <li>
                <RouteLink kind="text-only" to={Routes.settings()}>
                  Settings
                </RouteLink>
              </li>
            </ul>
            <ul className="mr-20 leading-8 mb-12 lg:mb-0">
              <h4 className="text-white font-bold text-sm mb-2 uppercase">Openfund</h4>
              <li>
                <a href="/d/openfund" className="text-gray hover:text-white">
                  Join Us
                </a>
              </li>
              <li>
                <a
                  href="https://docs.google.com/document/d/17sDUEnviGPU2AWluOEP4HHsST2BsyFgYvrnqtTXvcpY/edit"
                  target="_blank"
                  className="text-gray hover:text-white"
                  rel="noreferrer"
                >
                  Strategy
                </a>
              </li>
              {showOnePager && (
                <li>
                  <a href="/images/one-pager.pdf" target="_blank" className="text-gray hover:text-white">
                    Whitepaper
                  </a>
                </li>
              )}
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSenzXgYorMqrkI4o1K7yWFuh6AygALdG1nBORbu2elOb1Z_eg/viewform"
                  target="_blank"
                  className="text-gray hover:text-white"
                  rel="noreferrer"
                >
                  Launchpad
                </a>
              </li>
            </ul>
            <ul className="leading-8 mb-12 lg:mb-0">
              <h4 className="text-white font-bold text-sm mb-2 uppercase">Social</h4>
              <li>
                <a
                  href="https://diamondapp.com/u/openfund"
                  target="_blank"
                  className="text-gray hover:text-white"
                  rel="noreferrer"
                >
                  Diamond
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/openfund_com"
                  target="_blank"
                  className="text-gray hover:text-white"
                  rel="noreferrer"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/openfund"
                  target="_blank"
                  className="text-gray hover:text-white"
                  rel="noreferrer"
                >
                  Discord
                </a>
              </li>
              <li>
                <a
                  href="https://reddit.com/r/openfund"
                  target="_blank"
                  className="text-gray hover:text-white"
                  rel="noreferrer"
                >
                  Reddit
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="h-[120px] overflow-hidden relative mt-8">
          <img
            src="/images/image-planet-14.svg"
            className="height-auto w-[100px] z-10 absolute top-[0px] left-[150px] lg:left-[200px]"
          />
          <img src="/images/image-planet-13.svg" className="height-auto opacity-5 z-0 left-0" />
          <img
            src="/images/image-planet-15.svg"
            className="height-auto w-[70px] img--planet-15 z-10 absolute top-[30px] right-[20px] lg:right-[400px]"
          />
        </div>
      </footer>
    </div>
  );
}
