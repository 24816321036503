const STYLES = {
  'btn-primary': 'bg-blue text-white hover:bg-blue-dark border border-blue',
  'btn-primary-green': 'bg-green-base text-white hover:bg-green-dark border hover:border-green-dark border-green-base',
  'btn-primary-red': 'bg-red-dark text-white hover:bg-red-darker border border-red-dark hover:border-red-darker',
  'btn-secondary':
    'bg-white text-black hover:bg-blue hover:text-white border border-black hover:border-blue active:bg-gray',
  'btn-tertiary': 'bg-transparent text-gray border border-gray hover:text-black hover:border-black',
  'btn-tertiary-light': 'bg-transparent text-gray border border-gray hover:text-white hover:border-white',
  'btn-black': 'bg-black text-white',
  'text-only': 'text-gray hover:text-white',
  'text-only-light': 'text-black hover:underline',
  'text-only-underline': 'underline',
  'text-only-underline-light': 'text-gray underline',
};

const TEXT_SIZES = {
  sm: 'text-sm leading-6',
  md: 'text-md',
  lg: 'text-lg',
};

const PADDING = {
  sm: 'py-2 px-5',
  md: 'py-3 px-7',
  lg: 'py-5 px-10',
};

const CIRCLE_PADDING = {
  sm: 'p-2',
  md: 'p-2',
  lg: 'p-2',
};

type GetInteractiveElementClassNameArgs = {
  kind:
    | 'btn-primary'
    | 'btn-black'
    | 'btn-secondary'
    | 'btn-tertiary'
    | 'btn-tertiary-light'
    | 'btn-primary-green'
    | 'btn-primary-red'
    | 'text-only'
    | 'text-only-light'
    | 'text-only-underline'
    | 'text-only-underline-light';
  size: 'sm' | 'md' | 'lg';
  shape: 'square' | 'rounded' | 'circle';
  disabled?: boolean;
  extraClasses?: string | ((props: { isActive: boolean }) => string) | undefined;
};
export function getInteractiveElementClassName({
  kind,
  size,
  shape = 'square',
  extraClasses,
  disabled = false,
}: GetInteractiveElementClassNameArgs): string {
  const isButtonType = kind.startsWith('btn-');
  const paddingType = shape === 'circle' ? CIRCLE_PADDING : PADDING;
  const padding = isButtonType ? paddingType[size] : '';
  const borderRadius = shape !== 'square' ? 'rounded-full' : 'rounded-md';
  const baseClasses = `${disabled ? 'bg-gray-faint text-gray-light' : STYLES[kind]} ${
    TEXT_SIZES[size]
  } ${padding} ${borderRadius} inline-block`;
  return extraClasses ? `${extraClasses} ${baseClasses}` : baseClasses;
}

export type InteractiveElementProps = {
  kind?: GetInteractiveElementClassNameArgs['kind'];
  size?: GetInteractiveElementClassNameArgs['size'];
  shape?: GetInteractiveElementClassNameArgs['shape'];
  disabled?: GetInteractiveElementClassNameArgs['disabled'];
};
