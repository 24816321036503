import { useAppContext } from 'components/App';

export function useToast() {
  const { toast } = useAppContext();

  if (!toast) {
    throw new Error('toast is not defined.');
  }

  return toast;
}
