import { Logo } from 'components/core/Logo';
import { OpenfundContext } from 'contexts/Openfund';
import { GetAppStateResponse, ProfileEntryResponse } from 'deso-protocol-types';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { RoutePaths } from 'RoutePaths';
import { openfund, identity, nodeClient } from 'services';
import { OpenfundUser } from 'services/Openfund';
import { v4 as uuid } from 'uuid';
import { initializeTrackingOnce, trackingLogEvent } from '../utils/tracking';
import { CountdownTimer } from './app-ui/CountdownTimer';
import { FullPageError } from './app-ui/FullPageError';
import { NoDesoModal } from './app-ui/NoDesoModal';
import { SiteLoader } from './app-ui/SiteLoader';
import { Button } from './core/Button';
import { Heading } from './core/Heading';
import { Input } from './core/Input';
import { Text } from './core/Text';
import { Toast, ToastCloseProps, ToastProps, ToastShow } from './core/Toast';
import { GetExchangeRateUpdatedResponse } from '../services/NodeClient';
import { DESO_DOLLAR_PROFILE_NAME } from '../constants/AppConstants';
import { LegacyModeContextProvider } from '../contexts/LegacyModeContext';
import { isMobile } from '../utils/isMobile';

export type AppContext = {
  toast: ToastShow;
  setWaitingForDeposit: (isWaiting: boolean, txid: string, ticker: string) => void;
};
interface LocalAppState {
  isLoading: boolean;
  error: any;
  toasts: ToastProps[];
  isWaitingForDeposit: boolean;
  isNoDesoModalOpen: boolean;
}
export function App() {
  const [localState, setLocalState] = useState<LocalAppState>({
    isLoading: true,
    error: null,
    toasts: [],
    isWaitingForDeposit: false,
    isNoDesoModalOpen: false,
  });
  const [isLockedOut, setIsLockedOut] = useState(true);
  const [passwordInput, setPasswordInput] = useState('');
  const [isFailedLogin, setIsFailedLogin] = useState(false);
  const [currentUser, setCurrentUser] = useState<OpenfundUser | null>(null);
  const [exchangeRates, setExchangeRates] = useState<GetExchangeRateUpdatedResponse | null>(null);
  const [desoAppState, setDesoAppState] = useState<GetAppStateResponse | null>(null);
  const [usdProfileEntryResponse, setUSDProfileEntryResponse] = useState<ProfileEntryResponse | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { toasts } = localState;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const didRenderOnce = useRef(false);

  // On link to a non-whitelisted internal page,
  // scroll to the top of the page.
  useEffect(() => {
    if (didRenderOnce.current) {
      // @ts-ignore
      document.querySelector('[role="main"]')?.focus();
    }

    if (!pathname.startsWith(RoutePaths.trade.path) || isMobile()) {
      window.scrollTo(0, 0);
    }

    if (pathname.endsWith('/daodao')) {
      navigate(pathname.replace('/daodao', '/openfund'), { replace: true });
    }

    didRenderOnce.current = true;
  }, [pathname]);

  async function checkIfUserIsAllowed() {
    const accessPassword = localStorage.getItem('accessPassword');
    trackingLogEvent('home : land : access-granted', { password: accessPassword });
    setIsLockedOut(false);
    return;
  }

  useEffect(() => {
    const password = searchParams.get('password');

    if (password) {
      setPasswordInput(password);
      localStorage.setItem('accessPassword', password);
      checkIfUserIsAllowed().then(() => {
        setIsFailedLogin(isLockedOut);
        searchParams.delete('password');
        setSearchParams(searchParams);
      });
    }
  }, [searchParams]);

  useEffectOnce(() => {
    // Initialize tracking when the app loads
    initializeTrackingOnce();

    checkIfUserIsAllowed();
    identity.iframeLoadedPromise
      .then(() =>
        Promise.all([
          nodeClient.getExchangeRate().then(setExchangeRates),
          openfund.initializeAuthenticatedUser().then(setCurrentUser),
          nodeClient.getAppState().then(setDesoAppState),
          nodeClient
            .getProfileByUsername(DESO_DOLLAR_PROFILE_NAME)
            .then((res) => setUSDProfileEntryResponse(res.Profile)),
        ])
      )
      .then(() => {
        const lastPath = window.localStorage.getItem('lastPath');
        if (pathname === '/' && lastPath) {
          navigate(lastPath, { replace: true });
        }
      })
      .catch((e) => setLocalState({ ...localState, error: e }))
      .finally(() => {
        setLocalState({ ...localState, isLoading: false });
      });

    window.addEventListener('beforeunload', () => {
      window.localStorage.setItem('lastPath', window.location.pathname);
    });
  });

  if (isLockedOut && !localState.isLoading) {
    return (
      <div className="text-center w-10/12 lg:w-1/2 mx-auto h-screen flex flex-col justify-center">
        <form
          id="submit-access-password"
          onSubmit={async (ev) => {
            ev.preventDefault();
            localStorage.setItem('accessPassword', passwordInput);
            await checkIfUserIsAllowed();
            if (isLockedOut) {
              setIsFailedLogin(true);
            }
          }}
        >
          <div className="mb-30">
            <Logo />
          </div>
          <div className="mb-12 mt-12 p-12 border rounded-lg border-gray-333">
            <Text className="font-medium text-xl">Openfund will launch publicly on</Text>
            <Heading level={2} className="mt-3 text-lg">
              Wed Jun 1 2022 @ 12pm Pacific Time
            </Heading>
            <div className="text-4xl text-red-dark font-bold">
              <CountdownTimer
                shortUnit={true}
                timeUnitStyle={{ color: '#777' }}
                endTime={'Wed Jun 1 2022 12:00:00 GMT-0700 (Pacific Daylight Time)'}
              />
            </div>
          </div>
          <Heading level={2} className="font-sans">
            Do you HODL the key?
          </Heading>
          <Input
            labelText="Do you HODL the key?"
            placeholder="Enter Password"
            className="mb-6 w-6/12 text-center"
            autoFocus={true}
            autoComplete="off"
            spellCheck="false"
            maxLength={100}
            onInput={(ev) => {
              const password = ev.currentTarget.value;
              setPasswordInput(password);
              setIsFailedLogin(false);
            }}
          />
          <Button kind="btn-primary" shape="rounded" type="submit">
            Submit
          </Button>
          <p className="text-red animate-pulse mt-2">{isFailedLogin ? 'Invalid password' : ''}</p>
        </form>
      </div>
    );
  }

  if (localState.isLoading) {
    return (
      <div className="text-center mx-auto h-full flex flex-col justify-center">
        <SiteLoader />
      </div>
    );
  }

  if (localState.error) {
    return <FullPageError error={localState.error} />;
  }

  let toastObj = {
    show(props: any) {
      setLocalState({ ...localState, toasts: [{ id: uuid(), ...props }, ...toasts] });
    },
    removeToastWithManualID({ manualID }: ToastCloseProps) {
      setLocalState((oldLocalState) => {
        for (let ii = 0; ii < oldLocalState.toasts.length; ii++) {
          if (oldLocalState.toasts[ii].manualID === manualID) {
            return {
              ...oldLocalState,
              toasts: [...oldLocalState.toasts.slice(0, ii), ...oldLocalState.toasts.slice(ii + 1)],
            };
          }
        }
        // If we get here, there were no changes to make
        return oldLocalState;
      });
    },
    async hasManualID({ manualID }: ToastCloseProps): Promise<boolean> {
      return new Promise((resolve, reject) => {
        setLocalState((oldLocalState) => {
          for (let ii = 0; ii < oldLocalState.toasts.length; ii++) {
            if (oldLocalState.toasts[ii].manualID === manualID) {
              resolve(true);
              return oldLocalState;
            }
          }
          resolve(false);
          // If we get here, there were no changes to make
          return oldLocalState;
        });
      });
    },
  };
  const appContext: AppContext = {
    toast: toastObj,
    setWaitingForDeposit: (isWaiting: boolean, txid: string, ticker: string) => {
      if (txid) {
        if (isWaiting) {
          let explorerLink = '';
          switch (ticker) {
            case 'ETH':
              explorerLink = `https://etherscan.io/tx/${txid}`;
              break;
            case 'BTC':
              explorerLink = `https://www.blockchain.com/btc/tx/${txid}`;
              break;
            case 'SOL':
              explorerLink = `https://explorer.solana.com/tx/${txid}`;
              break;
          }

          toastObj.show({
            message: (
              <div>
                <div>Waiting for transaction to confirm</div>
                <div className="underline dark:text-gray-light dark:hover:text-gray-faint">
                  Click to check transaction status
                </div>
              </div>
            ),
            type: 'waiting',
            manualID: txid,
            link: explorerLink,
          });
        } else {
          toastObj.removeToastWithManualID({
            manualID: txid,
          });
        }
      }
    },
  };

  return (
    <OpenfundContext.Provider
      value={{
        currentUser,
        exchangeRates,
        desoAppState,
        setCurrentUser,
        usdProfileEntryResponse,
      }}
    >
      <LegacyModeContextProvider>
        {[0, 1, 2, 3, 4].map((ii) => {
          return (
            <canvas
              id={`my-canvas-${ii}`}
              key={`confetti-canvas-${ii}`}
              style={{ position: 'fixed', zIndex: 2000, height: '100%', width: '100%', pointerEvents: 'none' }}
            ></canvas>
          );
        })}

        <div id="sr-page-title-change-announcement" role="status" aria-live="polite" className="sr-only"></div>
        <div id="app-root" className="mt-24 lg:mt-28">
          <Outlet context={appContext} />
        </div>
        <div
          aria-live="assertive"
          className="fixed inset-0 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
        >
          {toasts.map(({ id, ...rest }, i) => {
            return (
              <Toast
                key={id}
                {...rest}
                close={() =>
                  setLocalState({
                    ...localState,
                    toasts: [...toasts.slice(0, i), ...toasts.slice(i + 1)],
                  })
                }
              />
            );
          })}
        </div>
        <NoDesoModal
          isOpen={localState.isNoDesoModalOpen}
          onClose={() => setLocalState({ ...localState, ...{ isNoDesoModalOpen: false } })}
        />
      </LegacyModeContextProvider>
    </OpenfundContext.Provider>
  );
}

export function useAppContext(): AppContext {
  return useOutletContext();
}
