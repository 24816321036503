import { DAOCoinLimitOrderOperationTypeString } from 'deso-protocol-types';
import { DESO_BTC_PUBLIC_KEY, DESO_ETH_PUBLIC_KEY, DESO_SOL_PUBLIC_KEY, DESO_USDC_PUBLIC_KEY } from './AppConstants';

// Tickers
export const DESO_USDC_TICKER = 'USDC';
export const DESO_TICKER = 'DESO';
export const USD_TICKER = 'USD';

// Projects
export const DESO_TOKEN_PUBLIC_KEY = 'DESO';
export const DESO_PROJECT_NAME = 'DESO';
export const DEFAULT_PROJECT_LEGACY_VIEW = 'Openfund';
export const DEFAULT_PROJECT = DESO_PROJECT_NAME;
export type WrappedToken = {
  publicKey: string;
  name: string;
  displayName: string;
  heroswapName: string;
  heroswapDisplayName: string;
};
export const DESO_WRAPPED_TOKENS = [
  {
    publicKey: DESO_TOKEN_PUBLIC_KEY,
    name: DESO_PROJECT_NAME,
    displayName: 'DESO',
    heroswapName: 'DESO',
    heroswapDisplayName: 'DESO',
  },
  {
    publicKey: DESO_USDC_PUBLIC_KEY,
    name: 'dUSDC_',
    displayName: 'USDC',
    heroswapName: 'DUSD',
    heroswapDisplayName: 'DUSDC',
  },
  {
    publicKey: DESO_BTC_PUBLIC_KEY,
    name: 'dBTC_',
    displayName: 'BTC',
    heroswapName: 'DBTC',
    heroswapDisplayName: 'DBTC',
  },
  {
    publicKey: DESO_ETH_PUBLIC_KEY,
    name: 'dETH_',
    displayName: 'ETH',
    heroswapName: 'DETH',
    heroswapDisplayName: 'DETH',
  },
  {
    publicKey: DESO_SOL_PUBLIC_KEY,
    name: 'dSOL_',
    displayName: 'SOL',
    heroswapName: 'DSOL',
    heroswapDisplayName: 'DSOL',
  },
] as WrappedToken[];

// Side
export const BID = DAOCoinLimitOrderOperationTypeString.DAOCoinLimitOrderOperationTypeStringBID;
export const ASK = DAOCoinLimitOrderOperationTypeString.DAOCoinLimitOrderOperationTypeStringASK;

export type BID_OR_ASK = typeof BID | typeof ASK;

// BID / ASK enum for converting graphql responses
export enum BID_OR_ASK_OP {
  ASK_OP = 1,
  BID_OP = 2,
}

// Fill Type
export const FILL_OR_KILL = 'FILL_OR_KILL';
export const IMMEDIATE_OR_CANCEL = 'IMMEDIATE_OR_CANCEL';
export const GOOD_TILL_CANCELLED = 'GOOD_TILL_CANCELLED';
export const RESERVED_DESO_NANOS_FOR_FEES = BigInt(50000);

// Wrapped assets
export const SHOW_NEW_BADGES = true;
