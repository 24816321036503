export const RoutePaths = {
  home: { path: '/' },
  discover: { path: '/discover' },
  activity: { path: '/activity' },
  activityDetail: { path: '/activity/:postHashHex' },
  login: { path: '/login' },
  projectOnboarding: {
    path: '/onboarding/project',
    email: { path: 'email' },
    username: { path: 'username' },
    profile: { path: 'profile' },
    details: { path: 'details' },
    deso: { path: 'deso' },
  },
  userOnboarding: {
    path: '/onboarding/user',
    email: { path: 'email' },
    profile: { path: 'profile' },
    follow: { path: 'follow' },
  },
  fund: { path: '/d/:username' },
  settings: {
    path: '/settings',
    profile: { path: 'profile' },
    project: { path: 'project' },
    fundraising: { path: 'fundraising' },
    distribution: { path: 'distribution' },
    proposals: { path: 'proposals' },
    notifications: { path: 'notifications' },
    advanced: { path: 'advanced' },
  },
  wallet: { path: '/wallet' },
  onePager: { path: '/one-pager' },
  orders: { path: '/orders' },
  tradeToken: { path: '/trade/:username' },
  trade: { path: '/trade' },
  notifications: { path: '/notifications' },
  profile: { path: '/profile/:username' },
  verifyEmail: { path: '/verify-email/:code' },
};

export const Routes = {
  home: () => RoutePaths.home.path,
  discover: () => RoutePaths.discover.path,
  activity: () => RoutePaths.activity.path,
  activityDetail: (postHashHex: string) => RoutePaths.activityDetail.path.replace(':postHashHex', postHashHex),
  login: () => RoutePaths.login.path,
  projectOnboarding: () => RoutePaths.projectOnboarding.path,
  projectOnboardingEmail: () => `${RoutePaths.projectOnboarding.path}/${RoutePaths.projectOnboarding.email.path}`,
  projectOnboardingUsername: () => `${RoutePaths.projectOnboarding.path}/${RoutePaths.projectOnboarding.username.path}`,
  projectOnboardingProfile: () => `${RoutePaths.projectOnboarding.path}/${RoutePaths.projectOnboarding.profile.path}`,
  projectOnboardingDetails: () => `${RoutePaths.projectOnboarding.path}/${RoutePaths.projectOnboarding.details.path}`,
  projectOnboardingCreate: () => `${RoutePaths.projectOnboarding.path}/${RoutePaths.projectOnboarding.deso.path}`,
  userOnboarding: () => RoutePaths.userOnboarding.path,
  userOnboardingEmail: () => `${RoutePaths.userOnboarding.path}/${RoutePaths.userOnboarding.email.path}`,
  userOnboardingProfile: () => `${RoutePaths.userOnboarding.path}/${RoutePaths.userOnboarding.profile.path}`,
  userOnboardingFollow: () => `${RoutePaths.userOnboarding.path}/${RoutePaths.userOnboarding.follow.path}`,
  fund: (username: string) => RoutePaths.fund.path.replace(':username', username),
  settings: () => RoutePaths.settings.path,
  settingsProfile: () => `${RoutePaths.settings.path}/${RoutePaths.settings.profile.path}`,
  settingsProject: () => `${RoutePaths.settings.path}/${RoutePaths.settings.project.path}`,
  settingsFundraising: () => `${RoutePaths.settings.path}/${RoutePaths.settings.fundraising.path}`,
  settingsProjectDistribution: () => `${RoutePaths.settings.path}/${RoutePaths.settings.distribution.path}`,
  settingsProposals: () => `${RoutePaths.settings.path}/${RoutePaths.settings.proposals.path}`,
  settingsNotifications: () => `${RoutePaths.settings.path}/${RoutePaths.settings.notifications.path}`,
  settingsAdvanced: () => `${RoutePaths.settings.path}/${RoutePaths.settings.advanced.path}`,
  wallet: () => RoutePaths.wallet.path,
  onePager: () => RoutePaths.onePager,
  orders: () => RoutePaths.orders.path,
  tradeToken: (username: string) => RoutePaths.tradeToken.path.replace(':username', username),
  trade: () => RoutePaths.trade.path,
  notifications: () => RoutePaths.notifications.path,
  profile: (username: string) => RoutePaths.profile.path.replace(':username', username),
  verifyEmail: (code: string) => RoutePaths.verifyEmail.path.replace(':code', code),
};
