import React from 'react';
import { Tooltip } from 'react-tooltip';

type BadgeProps = {
  size?: 's' | 'm' | 'l';
  text?: string;
  hideTooltip?: boolean;
};

const NewBadge: React.FC<BadgeProps> = ({ size = 's', text = 'NEW', hideTooltip = false }) => {
  const sizes = {
    s: 'text-[8px] leading-3 px-[4px] font-bold ml-1 relative top-[-6px]',
    m: 'text-[10px] leading-4 px-[6px] font-bold ml-1 relative top-[-6px]',
    l: 'text-[8px] leading-3 px-[4px] font-bold ml-1 relative top-[-6px]',
  };

  const tooltipContent = (
    <div className="font-base text-sm font-normal">
      <span>
        USDC, BTC, ETH, and SOL are represented in your wallet as wrapped assets, namely DUSDC, DBTC, DETH, and DSOL
        respectively. This allows you to trade them on the DeSo DEX, which is the first cross-chain order-book DEX, and
        to transact with virtually no gas!
        <br />
        You can convert real assets to wrapped assets, and vice versa, at any time simply by adding funds and cashing
        out in your DeSo wallet.
        <br />
        Give it a try!
      </span>
    </div>
  );

  return (
    <>
      <span
        className={`inline-block text-white bg-blue rounded ${sizes[size]}`}
        data-tooltip-id={hideTooltip ? '' : 'new-badge-tooltip'}
        style={{ marginTop: '-0.5rem' }}
      >
        {text}
      </span>

      {!hideTooltip && (
        <Tooltip id="new-badge-tooltip" style={{ maxWidth: '320px', textAlign: 'center' }} opacity={1}>
          {tooltipContent}
        </Tooltip>
      )}
    </>
  );
};

export default NewBadge;
