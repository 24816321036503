import { useEffect, useState } from 'react';
import { classNames } from 'utils/classNames';
import { ONE_DAY_MILLIS, ONE_HOUR_MILLIS, ONE_SECOND_MILLIS } from 'utils/date';

type TimeUnitType = 'days' | 'hours' | 'minutes' | 'seconds';
type CountdownTimerState = { [key in TimeUnitType]: number };

function getTimeRemaining(endTime: string): CountdownTimerState {
  const diff = new Date(endTime).getTime() - Date.now();
  return {
    days: Math.floor(diff / ONE_DAY_MILLIS),
    hours: Math.floor((diff / ONE_HOUR_MILLIS) % 24),
    minutes: Math.floor((diff / ONE_SECOND_MILLIS / 60) % 60),
    seconds: Math.floor((diff / ONE_SECOND_MILLIS) % 60),
  };
}

interface CountdownTimerProps {
  endTime: string;
  timeUnitStyle?: any;
  shortUnit?: boolean;
}
export function CountdownTimer({ endTime, timeUnitStyle, shortUnit }: CountdownTimerProps) {
  const [state, setState] = useState<CountdownTimerState>(getTimeRemaining(endTime));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setState(() => getTimeRemaining(endTime));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [endTime]);

  const timeUnits = Object.keys(state);

  return (
    <div className="flex pt-2 justify-center">
      {timeUnits
        .filter((k) => ['minutes', 'seconds'].includes(k) || state[k as TimeUnitType] > 0)
        .map((k, i) => (
          <TimeUnit
            key={k}
            value={state[k as TimeUnitType]}
            unit={k as TimeUnitType}
            timeUnitStyle={timeUnitStyle}
            className={classNames(i < timeUnits.length - 1 && 'mr-3')}
            shortUnit={shortUnit}
          />
        ))}
    </div>
  );
}

interface TimeUnitProps {
  value: number;
  unit: TimeUnitType;
  timeUnitStyle?: any;
  shortUnit?: boolean;
}
function TimeUnit({
  value,
  unit,
  className,
  timeUnitStyle,
  shortUnit,
}: TimeUnitProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  if (!timeUnitStyle || !Object.keys(timeUnitStyle)?.length) {
    timeUnitStyle = { color: 'grey' };
  }
  let unitStr = unit.toString();
  let output = <></>;
  if (shortUnit) {
    if (unit.includes('day')) {
      unitStr = 'd';
    } else if (unit.includes('second')) {
      unitStr = 's';
    } else if (unit.includes('minute')) {
      unitStr = 'm';
    } else {
      unitStr = 'h';
    }
    output = (
      <div>
        <span>{value}</span>
        <span style={timeUnitStyle}>{unitStr}</span>
      </div>
    );
  } else {
    if (value === 1) {
      // For removing plural
      unitStr = unit.substring(0, unit.length - 1);
    } else {
      unitStr = unit;
    }
    output = (
      <div>
        <span>{value}</span> <span style={timeUnitStyle}>{unitStr}</span>
      </div>
    );
  }

  return <div className={className}>{output}</div>;
}
