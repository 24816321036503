import { TokenLimitOrderEntryResponse } from 'services/NodeClient';
import { baseUnitsToTokens, desoNanosToDeso, parseFloatWithCommas, stripCommas } from 'utils/currency';
import { ASK, BID, BID_OR_ASK, BID_OR_ASK_OP } from '../constants/TradeConstants';
import { DesoTokenLimitOrder, TokenLimitOrdersDesoBuyingQuery } from '../graphql/codegen/graphql';

export const zero = BigInt('0');
const ten = BigInt('10');
const hundred = BigInt('100');
export const oneE9 = BigInt('1000000000');
export const oneE18 = BigInt('1000000000000000000');
export const oneE38 = BigInt('100000000000000000000000000000000000000');
export const oneE76 = oneE38 * oneE38;

export const DESO = 'DESO';

export type OPERATION_TYPE = 'BID' | 'ASK';
export const OPERATION_TYPES: {
  BID: OPERATION_TYPE;
  ASK: OPERATION_TYPE;
} = Object.freeze({
  BID: 'BID',
  ASK: 'ASK',
});

export function operationTypeToBidOrAskString(operationType: number | null | undefined): BID_OR_ASK {
  return bidOrAskToString(bidOrAsk(operationType));
}

export function bidOrAskToString(bidOrAskVal: BID_OR_ASK_OP): BID_OR_ASK {
  switch (bidOrAskVal) {
    case BID_OR_ASK_OP.ASK_OP:
      return ASK;
    case BID_OR_ASK_OP.BID_OP:
      return BID;
    default:
      throw new Error(`Unknown bidOrAsk: ${bidOrAskVal}`);
  }
}

export function bidOrAsk(operationType: number | null | undefined): BID_OR_ASK_OP {
  switch (operationType) {
    case 1:
      return BID_OR_ASK_OP.ASK_OP;
    case 2:
      return BID_OR_ASK_OP.BID_OP;
    default:
      throw new Error(`Unknown operationType: ${operationType}`);
  }
}

export interface PriceLevel {
  Side: OPERATION_TYPE;
  Price: bigint;
  Quantity: bigint;
  MySize: bigint;
}

export function buildPriceLevelBookBySide(
  orders: TokenLimitOrderEntryResponse[],
  denominatingToken: string,
  side: OPERATION_TYPE,
  mySizeTransactorPublicKeyBaseCheck: string
): PriceLevel[] {
  const priceLevels: { [price: string]: { Quantity: bigint; MySize: bigint } } = {};

  // Every order has two tokens:
  // - our desired denominating token
  // - the asset we are buying or selling, whose price will be denominated in the above token
  //
  // However, the DeSo DEX order book is generalized enough that an order can be constructed with
  // either token as the denominating token. In order to properly construct a price level view for one
  // side of the book with prices denominated in our denominating token, we need to do two things:
  // 1. Filter out orders on the opposite side of the book
  // 2. Normalize the price and quantity of orders to be in terms of our desired denominating token

  orders
    .filter((order) => {
      // If the desired operation type is BID, then it means we are buying the token by selling
      // the denominating token. Therefore, we want to filter out all order that are not selling
      // the denominating token.
      if (side === OPERATION_TYPES.BID) {
        return order.SellingDAOCoinCreatorPublicKeyBase58Check === denominatingToken;
      }

      // If the desired operation type is ASK, then it means we are selling the asset by buying
      // the denominating token. Therefore, we want to filter out all orders that are not buying
      // the denominating token.
      if (side === OPERATION_TYPES.ASK) {
        return order.BuyingDAOCoinCreatorPublicKeyBase58Check === denominatingToken;
      }

      // We should never reach this point. If we do, it means the order is improperly constructed.
      // We filter out such orders to be safe.
      return false;
    })
    .forEach((order) => {
      const rawOrderPrice = priceDecimalStringToBigInt(order.Price);
      const rawOrderQuantity = quantityDecimalStringToBigInt(order.Quantity);

      // If the order's operation type matches the desired side, then the price is denominated in our
      // desired denominating token. We can use the price as is in that case. Otherwise, we need to
      // invert the price.
      const price = order.OperationType === side ? rawOrderPrice : invertPrice(rawOrderPrice);

      // If the order's operation type matches the desired side, then the quantity refers to the
      // desired asset. Otherwise, the quantity refers to the denominating coin and needs to be
      // converted to the desired asset.
      const quantity =
        order.OperationType === side ? rawOrderQuantity : mulPriceAndQuantity(rawOrderPrice, rawOrderQuantity);

      const priceString = priceBigIntToDecmialString(price);
      const existingPriceLevel = priceLevels[priceString];

      priceLevels[priceString] = {
        Quantity: (existingPriceLevel?.Quantity ?? zero) + quantity,
        MySize:
          (existingPriceLevel?.MySize ?? zero) +
          (order.TransactorPublicKeyBase58Check === mySizeTransactorPublicKeyBaseCheck ? quantity : zero),
      };
    });

  const collapsedPriceLevels: PriceLevel[] = Object.entries(priceLevels).map((priceLevel) => {
    return {
      Side: side,
      Price: priceDecimalStringToBigInt(priceLevel[0]),
      Quantity: priceLevel[1].Quantity,
      MySize: priceLevel[1].MySize,
    };
  });

  return collapsedPriceLevels.sort((a, b) => (a.Price - b.Price > zero ? -1 : 1));
}

export function sumTokenSellQuantityForTransactor(
  orders: TokenLimitOrderEntryResponse[],
  token: string,
  transactorPublicKeyBaseCheck: string
): bigint {
  let sum = BigInt(0);
  orders.forEach((order) => {
    if (order.TransactorPublicKeyBase58Check !== transactorPublicKeyBaseCheck) {
      return;
    }
    if (order.OperationType !== OPERATION_TYPES.ASK || order.SellingDAOCoinCreatorPublicKeyBase58Check !== token) {
      return;
    }
    sum += quantityDecimalStringToBigInt(order.Quantity);
  });
  return sum;
}

export function sumTokenBuyDESOCostForTransactor(
  orders: TokenLimitOrderEntryResponse[],
  token: string,
  transactorPublicKeyBaseCheck: string
): bigint {
  let sum = BigInt(0);
  if (token === DESO) {
    orders.forEach((order) => {
      if (order.TransactorPublicKeyBase58Check !== transactorPublicKeyBaseCheck) {
        return;
      }
      if (order.OperationType !== OPERATION_TYPES.ASK || order.SellingDAOCoinCreatorPublicKeyBase58Check !== token) {
        return;
      }
      sum += quantityDecimalStringToBigInt(order.Quantity, BigInt(1e9));
    });
    return sum;
  }
  orders.forEach((order) => {
    if (order.TransactorPublicKeyBase58Check !== transactorPublicKeyBaseCheck) {
      return;
    }
    if (order.OperationType !== OPERATION_TYPES.BID || order.BuyingDAOCoinCreatorPublicKeyBase58Check !== token) {
      return;
    }
    sum += (quantityDecimalStringToBigInt(order.Quantity) * priceDecimalStringToBigInt(order.Price)) / oneE38;
  });
  return sum / oneE9;
}

export function sumTokenBuyCostForTransactor(
  orders: TokenLimitOrderEntryResponse[],
  token: string,
  transactorPublicKeyBaseCheck: string
): bigint {
  let sum = BigInt(0);
  orders.forEach((order) => {
    if (order.TransactorPublicKeyBase58Check !== transactorPublicKeyBaseCheck) {
      return;
    }
    if (order.OperationType !== OPERATION_TYPES.BID || order.BuyingDAOCoinCreatorPublicKeyBase58Check !== token) {
      return;
    }
    sum += (quantityDecimalStringToBigInt(order.Quantity) * priceDecimalStringToBigInt(order.Price)) / oneE38;
  });
  return sum;
}

export function usdDecimalStringToDesoDecimalString(
  usdAmount: string,
  usdCentsPerDeso: string,
  toFixedDecimalPlaces = -1
): string {
  const usd = priceDecimalStringToBigInt(usdAmount);
  const xRate = priceDecimalStringToBigInt(usdCentsPerDeso);
  return priceBigIntToDecmialString((oneE38 * usd) / (xRate / hundred), toFixedDecimalPlaces);
}

export function desoDecimalStringTUsdDecimalString(
  desoAmount: string,
  usdCentsPerDeso: string,
  toFixedDecimalPlaces = -1
): string {
  const deso = priceDecimalStringToBigInt(desoAmount);
  const xRate = priceDecimalStringToBigInt(usdCentsPerDeso);
  return priceBigIntToDecmialString((deso * xRate) / hundred / oneE38, toFixedDecimalPlaces);
}

export function invertPrice(val: bigint): bigint {
  // Ex: say val = 3 but is multiplied by a scaling factor of 100, so val = 300 as a bigint
  //
  // If we want to invert 3 to compute 1/3 but want to do it using the scaled bigints,
  // we can use the math (100 * 100 / 300) = 33, which scales back down to 0.33
  //
  // This function achieves the same thing but for numbers with a scaling factor of 1e38
  return (oneE38 * oneE38) / val;
}

export function mulPriceAndQuantity(price: bigint, quantity: bigint): bigint {
  return (price * quantity) / oneE38;
}

export function priceDecimalStringToBigInt(price: string): bigint {
  return convertDecimalStringToBigInt(price, oneE38);
}

export function priceBigIntToDecmialString(price: bigint, toFixedDecimalPlaces = -1): string {
  return convertBigIntToDecimalString(price, oneE38, toFixedDecimalPlaces);
}

export function priceBigIntToFloat(price: bigint): number {
  return parseFloatWithCommas(priceBigIntToDecmialString(price));
}

export function quantityDecimalStringToBigInt(quantity: string, scalingFactor: bigint = oneE18): bigint {
  return convertDecimalStringToBigInt(quantity, scalingFactor);
}

export function quantityBigIntToDecmialString(
  quantity: bigint,
  toFixedDecimalPlaces = -1,
  scalingFactor: bigint = oneE18
): string {
  return convertBigIntToDecimalString(quantity, scalingFactor, toFixedDecimalPlaces);
}

export function quantityBigIntToFloat(quantity: bigint): number {
  return parseFloatWithCommas(quantityBigIntToDecmialString(quantity));
}

export function desoDecimalStringToBigInt(deso: string, scalingFactor: bigint = oneE9): bigint {
  return convertDecimalStringToBigInt(deso, scalingFactor);
}

export function desoBigIntToDecimalString(nanos: bigint, toFixedDecimalPlaces = -1): string {
  return convertBigIntToDecimalString(nanos, oneE9, toFixedDecimalPlaces);
}

export function desoBigIntToFloat(nanos: bigint): number {
  return parseFloatWithCommas(desoBigIntToDecimalString(nanos));
}

export function convertDecimalStringToBigInt(decimalString: string, scalingFactor: bigint): bigint {
  decimalString = stripCommas(decimalString);
  let vals = decimalString.split('.');
  if (vals.length === 0) {
    vals = ['0', '0'];
  }
  // In this case, we had a whole number like 123, with no decimal
  // so we add a "0" as the decimal.
  if (vals.length !== 2) {
    vals.push('0');
  }
  // This can happen if we have something like ".123"
  if (vals[0] === '') {
    vals[0] = '0';
  }
  // This can happen if we have something like "123."
  if (vals[1] === '') {
    vals[1] = '0';
  }

  const numDecimalPlacesInString = vals[1].length;

  let wholePart = BigInt(vals[0]) * scalingFactor;
  let decimalPart = BigInt(vals[1]) * scalingFactor;

  // Given an input string 1.123, this produces
  // decimalPart = 123 * scalingFactor * (10 ^ -numDecimalPlacesInString)
  for (let i = 0; i < numDecimalPlacesInString; ++i) {
    decimalPart = decimalPart / ten;
  }

  return wholePart + decimalPart;
}

export function convertBigIntToDecimalString(val: bigint, scalingFactor: bigint, toFixedDecimalPlaces = -1): string {
  const wholeNumber = val / scalingFactor;
  const decimalPart = val % scalingFactor;

  if (decimalPart === zero || toFixedDecimalPlaces === 0) {
    return wholeNumber.toString();
  }

  const scalingFactorDigits = getNumDigits(scalingFactor);
  let decimalPartAsString = decimalPart.toString();

  // Left pad the decimal part with zeros
  if (decimalPartAsString.length !== scalingFactorDigits) {
    const decimalLeadingZeros = '0'.repeat(scalingFactorDigits - decimalPartAsString.length - 1);
    decimalPartAsString = `${decimalLeadingZeros}${decimalPartAsString}`;
  }

  // Trim trailing zeros
  decimalPartAsString = decimalPartAsString.replace(/\.0+$/, '');

  // Pad with additional trailing zeros if needed
  if (toFixedDecimalPlaces > 0) {
    decimalPartAsString = decimalPartAsString + '0'.repeat(toFixedDecimalPlaces);
    decimalPartAsString = decimalPartAsString.substring(0, toFixedDecimalPlaces);
  }

  return `${wholeNumber}.${decimalPartAsString}`;
}

function getNumDigits(val: bigint): number {
  let numDigits = 0;
  while (val !== zero) {
    ++numDigits;
    val = val / ten;
  }
  return numDigits;
}

// This calculates price as a bigint that's scaled up by 1e38 and then converts it back to a human-readable decimal string.
//  The denominator for the output price is determined by the operation type:
// - If operation type = BID, then price is the number of selling coins per buying coin
// - If operation type = ASK, then price is the number of buying coins per selling coin
function scaledExchangeRateToPriceString(
  scaledExchangeRate: string,
  buyingPublicKey: string,
  sellingPublicKey: string,
  operationType: 'BID' | 'ASK'
): string {
  let price = BigInt(scaledExchangeRate);
  if (buyingPublicKey === 'DESO') {
    price = price / oneE9;
  } else if (sellingPublicKey === 'DESO') {
    price = price * oneE9;
  }

  if (operationType === 'ASK') {
    price = oneE76 / price;
  }

  return convertBigIntToDecimalString(price, oneE38);
}

// This calculates ExchangeRateCoinsToSellPerCoinsToBuy as an unscaled coin-level exchange rate for the coin pair.
// The value is always denominated as the number of selling coins per buying coin.
function scaledExchangeRateToUnscaledFloat(
  scaledExchangeRateStr: string,
  buyingPublicKey: string,
  sellingPublicKey: string
) {
  let scaledExchangeRate = BigInt(scaledExchangeRateStr);
  if (buyingPublicKey === 'DESO') {
    scaledExchangeRate = scaledExchangeRate / oneE9;
  } else if (sellingPublicKey === 'DESO') {
    scaledExchangeRate = scaledExchangeRate * oneE9;
  }

  return Number(convertBigIntToDecimalString(scaledExchangeRate, oneE38));
}

const getOrderSellingPublicKey = (order: DesoTokenLimitOrder) => {
  return order.creatorSoldAccount?.publicKey ?? 'DESO';
};

const isOrderSellingDESO = (order: DesoTokenLimitOrder) => {
  return !order.creatorSoldAccount?.publicKey;
};

const getOrderBuyingPublicKey = (order: DesoTokenLimitOrder) => {
  return order.creatorBoughtAccount?.publicKey ?? 'DESO';
};

const isOrderBuyingDESO = (order: DesoTokenLimitOrder) => {
  return !order.creatorBoughtAccount?.publicKey;
};

const getBaseUnitsToSell = (order: DesoTokenLimitOrder): bigint => {
  // For ask orders, quantity to sell is just quantity to fill in base units
  if (order.operationType === BID_OR_ASK_OP.ASK_OP) {
    return BigInt(order.quantityToFillInBaseUnitsNumeric);
  }
  return (
    (BigInt(order.quantityToFillInBaseUnitsNumeric) * BigInt(order.scaledExchangeRateCoinsToSellPerCoinToBuyNumeric)) /
    oneE38
  );
};

export const graphQLOrderBookToTokenLimitEntry = (
  results: Array<{
    data: TokenLimitOrdersDesoBuyingQuery | undefined;
  }>
) => {
  const orders = results.flatMap((e) => e.data?.desoTokenLimitOrders?.nodes || []) as DesoTokenLimitOrder[];

  // Aggregate orders such that we have a map of keys representing transactor + selling coin
  // mapped to an array of orders.
  const aggregatedOrders = orders.reduce((acc: { [k: string]: DesoTokenLimitOrder[] }, cur) => {
    if (!(cur?.transactorAccount?.publicKey && cur?.sellingDaoCoinCreatorPkid)) {
      // this will never happen, but it makes typescript happy.
      return acc;
    }
    const key = cur.transactorAccount.publicKey + cur.sellingDaoCoinCreatorPkid;
    if (!(key in acc)) {
      acc[key] = [];
    }
    acc[key].push(cur);
    return acc;
  }, {});

  // We'll keep track of all the valid orders in one array.
  const validOrders = [] as DesoTokenLimitOrder[];
  Object.entries(aggregatedOrders).forEach(([_, transactorOrders]) => {
    if (!transactorOrders.length) {
      return;
    }

    // Sort the orders by priority (lowest to highest price, breaking ties w/ block height).
    transactorOrders.sort((a, b) => {
      // We use the scaled exchange rate to sort limit orders in the sequence they would be matched
      // against orders on the opposite side of the book. The coin to sell is on the numerator side of
      // the exchange rate, meaning higher exchange rates have a higher precedence in the the order
      // book and will be matched first.
      const diff =
        BigInt(a.scaledExchangeRateCoinsToSellPerCoinToBuyNumeric) -
        BigInt(b.scaledExchangeRateCoinsToSellPerCoinToBuyNumeric);
      if (diff > 0) {
        return -1;
      }
      if (diff < 0) {
        return 1;
      }
      return Number(a.blockHeight) - Number(b.blockHeight);
    });

    // Keep track of the running balance of the transactor's account.
    let runningBalance = isOrderSellingDESO(transactorOrders[0])
      ? BigInt(transactorOrders[0].transactorAccount?.desoBalance?.balanceNanos || 0)
      : BigInt(transactorOrders[0].transactorSellingTokenBalance?.balanceNanos || 0);

    // For each order, get the units to sell and check if we have enough balance to fill it.
    // If we don't have enough balance, we can't fill any more orders and will break.
    // If we do have enough balance, append it to the list of valid orders and subtract the
    // units to sell from the running balance.
    for (let order of transactorOrders) {
      if (runningBalance === zero) {
        return;
      }
      const unitsToSell = getBaseUnitsToSell(order);
      if (unitsToSell > runningBalance) {
        const quantityToFillInBaseUnitsNumeric =
          order.operationType === BID_OR_ASK_OP.ASK_OP
            ? runningBalance
            : (runningBalance * oneE38) / BigInt(order.scaledExchangeRateCoinsToSellPerCoinToBuyNumeric);
        validOrders.push({
          ...order,
          quantityToFillInBaseUnitsNumeric: quantityToFillInBaseUnitsNumeric.toString(),
        });
        return;
      }
      runningBalance -= unitsToSell;
      validOrders.push(order);
    }
  });

  return validOrders.map((order: DesoTokenLimitOrder) => {
    const isBid = order.operationType === BID_OR_ASK_OP.BID_OP;
    const isBuyingDESO = isOrderBuyingDESO(order);
    const isSellingDESO = isOrderSellingDESO(order);

    const isDesoDenominated = (isBuyingDESO && isBid) || (isSellingDESO && !isBid);
    const scaledExchangeRate = order.scaledExchangeRateCoinsToSellPerCoinToBuyNumeric;
    const buyingPublicKey = getOrderBuyingPublicKey(order);
    const sellingPublicKey = getOrderSellingPublicKey(order);
    return {
      TransactorPublicKeyBase58Check: order.transactorAccount?.publicKey,
      BuyingDAOCoinCreatorPublicKeyBase58Check: buyingPublicKey,
      SellingDAOCoinCreatorPublicKeyBase58Check: sellingPublicKey,
      Quantity: isDesoDenominated
        ? quantityBigIntToDecmialString(BigInt(order.quantityToFillInBaseUnitsNumeric), 9, oneE9)
        : quantityBigIntToDecmialString(BigInt(order.quantityToFillInBaseUnitsNumeric), 18, oneE18),
      QuantityToFill: isDesoDenominated
        ? desoNanosToDeso(order.quantityToFillInBaseUnitsNumeric)
        : baseUnitsToTokens(order.quantityToFillInBaseUnitsNumeric),
      Price: scaledExchangeRateToPriceString(
        scaledExchangeRate,
        buyingPublicKey,
        sellingPublicKey,
        operationTypeToBidOrAskString(order.operationType)
      ),
      ExchangeRateCoinsToSellPerCoinToBuy: scaledExchangeRateToUnscaledFloat(
        scaledExchangeRate,
        buyingPublicKey,
        sellingPublicKey
      ),
      OperationType: operationTypeToBidOrAskString(order.operationType),
      OrderID: order.orderId,
    } as TokenLimitOrderEntryResponse;
  });
};
