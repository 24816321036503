const COLORS = Object.freeze({
  primary: '',
  secondary: 'text-gray',
  error: 'text-red',
  success: 'text-green',
  warning: 'text-yellow',
});

const SIZES = Object.freeze({
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-xl',
  xl: 'text-3xl',
});

type TextProps = {
  tag?: keyof JSX.IntrinsicElements;
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'warning';
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

export function Text({
  tag = 'p',
  color = 'primary',
  size = 'md',
  children,
  className = '',
  ...rest
}: TextProps & React.HTMLAttributes<any>) {
  const baseClasses = `${SIZES[size]} ${COLORS[color]}`;
  const classes = className ? `${className} ${baseClasses}` : baseClasses;
  const TextEl = tag;

  return (
    <TextEl className={`${classes}`} {...rest}>
      {children}
    </TextEl>
  );
}
