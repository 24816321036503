import { AxiosInstance } from 'axios';
import { MIN_FEE_RATE_NANOS_PER_KB } from 'constants/AppConstants';
import {
  BalanceEntryResponse,
  CreateFollowTxnStatelessRequest,
  CreateLikeStatelessRequest,
  CreateLikeStatelessResponse,
  DAOCoinLimitOrderWithCancelOrderIDRequest,
  DAOCoinRequest,
  DAOCoinResponse,
  GetAppStateResponse,
  GetExchangeRateResponse,
  GetHodlersForPublicKeyResponse,
  GetNFTsCreatedByPublicKeyRequest,
  GetNFTsCreatedByPublicKeyResponse,
  GetProfilesResponse,
  GetSingleProfileResponse,
  GetTxnResponse,
  GetUsersResponse,
  PostEntryResponse,
  ProfileEntryResponse,
  SendDeSoRequest,
  SendDiamondsRequest,
  SubmitPostRequest,
  SubmitTransactionRequest,
  TransferDAOCoinRequest,
  TransferDAOCoinResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
  UpdateTutorialStatusRequest,
} from 'deso-protocol-types';
import { nodeClient } from './index';
import orderBy from 'lodash/orderBy';

export interface GetUsersOptions {
  SkipForLeaderboard?: boolean;
  SkipHodlings?: boolean;
  refreshCache?: boolean;
  IncludeBalance?: boolean;
}

export interface GetIngressCookieResponse {
  CookieValue: string;
}

interface UploadImageParams {
  jwt: string;
  publicKey: string;
  file: File;
}

export interface TokenLimitOrderParams {
  TransactorPublicKeyBase58Check: string;
  BuyingDAOCoinCreatorPublicKeyBase58Check: string;
  SellingDAOCoinCreatorPublicKeyBase58Check: string;
  Price: string;
  Quantity: string;
  OperationType: string;
  FillType: string;
  MinFeeRateNanosPerKB: number;
}

export interface TokenMarketOrderParams {
  TransactorPublicKeyBase58Check: string;
  BuyingDAOCoinCreatorPublicKeyBase58Check: string;
  SellingDAOCoinCreatorPublicKeyBase58Check: string;
  Quantity: string;
  OperationType: string;
  FillType: string;
  MinFeeRateNanosPerKB: number;
}

export interface TokenLimitOrderEntryResponse {
  TransactorPublicKeyBase58Check: string;
  BuyingDAOCoinCreatorPublicKeyBase58Check: string;
  SellingDAOCoinCreatorPublicKeyBase58Check: string;
  Price: string;
  Quantity: string;
  ExchangeRateCoinsToSellPerCoinToBuy: number;
  QuantityToFill: number;
  OperationType: string;
  OrderID: string;
}

export type GetExchangeRateUpdatedResponse = GetExchangeRateResponse & {
  USDCentsPerDeSoCoinbase: number;
};

export class NodeClient {
  private getUsersCache: Record<string, GetUsersResponse> = {};
  private singleProfileResponseCache: Record<string, GetSingleProfileResponse> = {};
  private axiosClient: AxiosInstance;

  private get nodeUrl() {
    return this.axiosClient.defaults.baseURL;
  }

  get cacheState() {
    return {
      getUsersStateless: this.getUsersCache,
      getSingleProfile: this.singleProfileResponseCache,
    };
  }

  constructor(axiosClient: AxiosInstance) {
    this.axiosClient = axiosClient;
  }

  async getProfileByPublicKey(publicKey: string, errorOnMissing = true): Promise<GetSingleProfileResponse> {
    if (this.singleProfileResponseCache[publicKey] !== undefined) {
      return this.singleProfileResponseCache[publicKey];
    }

    const { data } = await this.axiosClient.post('get-single-profile', {
      PublicKeyBase58Check: publicKey,
      Username: '',
      NoErrorOnMissing: !errorOnMissing,
    });

    if (data.Profile?.PublicKeyBase58Check) {
      this.singleProfileResponseCache[data.Profile.PublicKeyBase58Check] = data;
    }

    return data;
  }

  async getProfileByUsername(
    username: string,
    errorOnMissing = true,
    refreshCache = false
  ): Promise<GetSingleProfileResponse> {
    if (!refreshCache && this.singleProfileResponseCache[username] !== undefined) {
      return this.singleProfileResponseCache[username];
    }

    const { data } = await this.axiosClient.post('get-single-profile', {
      PublicKeyBase58Check: '',
      Username: username,
      NoErrorOnMissing: !errorOnMissing,
    });

    if (data.Profile?.Username) {
      this.singleProfileResponseCache[data.Profile.Username] = data;
    }
    // Hack to patch up ExtraData when it doesn't get set. This is mainly needed
    // prior to the Openfund hard fork.
    if (data && data.Profile && !data.Profile.ExtraData) {
      data.Profile.ExtraData = {};
    }

    return data;
  }

  async getProfilesByUsername(
    UsernamePrefix: string,
    ReaderPublicKeyBase58Check?: string,
    NumToFetch = 5
  ): Promise<Array<ProfileEntryResponse>> {
    const normalizedPrefix = UsernamePrefix.toLowerCase().trim();

    let usersList = await this.axiosClient
      .post('get-profiles', {
        PublicKeyBase58Check: '',
        Username: '',
        UsernamePrefix: normalizedPrefix,
        Description: '',
        // TODO: should this be set to something?
        OrderBy: '',
        NumToFetch,
        ReaderPublicKeyBase58Check,
        ModerationType: '',
        FetchUsersThatHODL: false,
        AddGlobalFeedBool: false,
      })
      .then(({ data }: { data: GetProfilesResponse }) => data.ProfilesFound || []);

    const hasExactMatch = usersList.some((e) => e.Username.toLowerCase() === normalizedPrefix);

    if (hasExactMatch) {
      usersList = orderBy(usersList, [(user) => (user.Username.toLowerCase() === normalizedPrefix ? 0 : 1)]);
    } else {
      const exactUserMatch = await nodeClient
        .getProfileByUsername(normalizedPrefix)
        .then((e) => e.Profile)
        .catch(() => null);

      if (exactUserMatch) {
        usersList = [exactUserMatch, ...usersList];
      }
    }

    return usersList;
  }

  async getUsers(
    publicKeys: readonly string[],
    {
      SkipForLeaderboard = true,
      SkipHodlings = true,
      refreshCache = false,
      IncludeBalance = true,
    }: GetUsersOptions = {}
  ) {
    const cacheKey = `${publicKeys.join(':')}${JSON.stringify({
      SkipForLeaderboard,
      SkipHodlings,
      IncludeBalance,
    })}`;

    if (this.getUsersCache[cacheKey] && !refreshCache) {
      return this.getUsersCache[cacheKey];
    }

    const res = await this.axiosClient.post('get-users-stateless', {
      SkipForLeaderboard,
      SkipHodlings,
      PublicKeysBase58Check: publicKeys,
      IncludeBalance,
    });

    this.getUsersCache[cacheKey] = res.data;

    this.getUsersCache[cacheKey].UserList?.forEach((user) => {
      this.singleProfileResponseCache[user.PublicKeyBase58Check] = {
        IsBlacklisted: user.IsBlacklisted,
        IsGraylisted: user.IsGraylisted,
        Profile: user.ProfileEntryResponse,
      };
    });

    return this.getUsersCache[cacheKey];
  }

  getProfilePicUrl(publicKey: string = '', fallback: boolean = true): string {
    const fallbackQueryParam = fallback ? `?fallback=${window.location.origin}/images/ghost-profile-image.svg` : '';
    return `${this.nodeUrl}/get-single-profile-picture/${publicKey}${fallbackQueryParam}`;
  }

  async uploadImage({ jwt, publicKey, file }: UploadImageParams): Promise<string> {
    const data = new FormData();
    data.append('file', file);
    data.append('UserPublicKeyBase58Check', publicKey);
    data.append('JWT', jwt);
    // NOTE: we hardcode it to upload images to node.deso.org even when running against testnet,
    // since uploading images via test.deso.org doesn't currently work.
    const res = await this.axiosClient.post('upload-image', data);

    return res.data.ImageURL;
  }

  async getExchangeRate(): Promise<GetExchangeRateUpdatedResponse> {
    const { data } = await this.axiosClient.get('get-exchange-rate');
    return data;
  }

  async getIngressCookie(): Promise<GetIngressCookieResponse> {
    delete this.axiosClient.defaults.headers.common['Cookie'];
    const { data } = await this.axiosClient.get('get-ingress-cookie');
    return data;
  }

  async getAppState(): Promise<GetAppStateResponse> {
    const { data } = await this.axiosClient.post('get-app-state', { PublicKeyBase58Check: '' });
    return data;
  }

  async getProjectActivityFeed(projectOwnerPkid: string, lastSeenPostHashHex = ''): Promise<PostEntryResponse[]> {
    return this.axiosClient
      .post('https://node0.deso.org/api/v0/get-posts-stateless', {
        PostHashHex: lastSeenPostHashHex,
        ReaderPublicKeyBase58Check: '',
        OrderBy: '',
        StartTstampSecs: null,
        PostContent: '',
        NumToFetch: 25,
        FetchSubcomments: false,
        GetPostsForFollowFeed: false,
        GetPostsForGlobalWhitelist: true,
        GetPostsByDESO: false,
        MediaRequired: false,
        PostsByDESOMinutesLookback: 0,
      })
      .then((res) => {
        return (res.data.PostsFound as PostEntryResponse[])?.filter(({ IsHidden }) => !IsHidden) ?? [];
      });
  }

  async getFollowerFeed(
    ReaderPublicKeyBase58Check: string | undefined,
    lastSeenPostHashHex: string = ''
  ): Promise<PostEntryResponse[]> {
    return this.axiosClient
      .post('get-posts-stateless', {
        PostHashHex: lastSeenPostHashHex,
        ReaderPublicKeyBase58Check,
        OrderBy: 'newest',
        StartTstampSecs: null,
        PostContent: '',
        NumToFetch: 25,
        FetchSubcomments: false,
        GetPostsForFollowFeed: true,
        GetPostsForGlobalWhitelist: true,
        GetPostsByDESO: false,
        MediaRequired: false,
        PostsByDESOMinutesLookback: 0,
        AddGlobalFeedBool: true,
      })
      .then((res) => {
        return (res.data.PostsFound as PostEntryResponse[])?.filter(({ IsHidden }) => !IsHidden) ?? [];
      });
  }

  async getFeedForUser(
    PublicKeyBase58Check: string,
    ReaderPublicKeyBase58Check: string,
    lastSeenPostHashHex: string = ''
  ): Promise<PostEntryResponse[]> {
    return this.axiosClient
      .post('get-posts-for-public-key', {
        PublicKeyBase58Check,
        ReaderPublicKeyBase58Check,
        LastPostHashHex: lastSeenPostHashHex,
        NumToFetch: 25,
        MediaRequired: false,
      })
      .then((res) => {
        return (res.data.Posts as PostEntryResponse[])?.filter(({ IsHidden }) => !IsHidden) ?? [];
      });
  }

  async getGlobalActivityFeed(
    ReaderPublicKeyBase58Check?: string,
    lastSeenPostHashHex: string = ''
  ): Promise<PostEntryResponse[]> {
    return this.axiosClient
      .post('get-posts-stateless', {
        PostHashHex: lastSeenPostHashHex,
        ReaderPublicKeyBase58Check: ReaderPublicKeyBase58Check,
        OrderBy: 'newest',
        StartTstampSecs: null,
        PostContent: '',
        NumToFetch: 50,
        FetchSubcomments: false,
        GetPostsForFollowFeed: false,
        GetPostsForGlobalWhitelist: false,
        GetPostsByDESO: false,
        MediaRequired: false,
        PostsByDESOMinutesLookback: 0,
        AddGlobalFeedBool: false,
      })
      .then((res) => {
        return (res.data.PostsFound as PostEntryResponse[])?.filter(({ IsHidden }) => !IsHidden) ?? [];
      });
  }

  // NOTE: We *might* need to revisit this in the future for pagination. For now, we just
  // fetch everything.
  async getAllProjectHoldings(PublicKeyBase58Check: string): Promise<GetHodlersForPublicKeyResponse> {
    const { data } = await this.axiosClient.post('get-hodlers-for-public-key', {
      FetchAll: true,
      FetchHodlings: true,
      IsDAOCoin: true,
      PublicKeyBase58Check,
    });

    return data;
  }

  async getHodlersCountForPublicKeys(
    PublicKeysBase58Check: string[],
    IsDAOCoin: boolean
  ): Promise<{ [publicKey: string]: number }> {
    const { data } = await this.axiosClient.post('get-hodlers-count-for-public-keys', {
      PublicKeysBase58Check,
      IsDAOCoin,
    });
    return data;
  }

  async getProjectHolders(PublicKeyBase58Check: string): Promise<GetHodlersForPublicKeyResponse> {
    const { data } = await this.axiosClient.post('get-hodlers-for-public-key', {
      FetchAll: true,
      FetchHodlings: false,
      IsDAOCoin: true,
      PublicKeyBase58Check,
    });

    return data;
  }

  // TODO: Migrate this to use deso-protocol once types are updated.
  async getProjectHoldings(
    Username: string,
    SortType: string = 'wealth',
    NumToFetch: number = 20,
    FetchAll: boolean = false
  ): Promise<GetHodlersForPublicKeyResponse> {
    const { data } = await this.axiosClient.post('get-hodlers-for-public-key', {
      FetchAll,
      FetchHodlings: true,
      NumToFetch,
      IsDAOCoin: true,
      Username,
      SortType,
    });

    return data;
  }

  async getProjectHolding(
    PublicKeyBase58Check: string,
    IsHodlingPublicKeyBase58Check: string
  ): Promise<BalanceEntryResponse> {
    const { data } = await this.axiosClient.post('is-hodling-public-key', {
      PublicKeyBase58Check,
      IsHodlingPublicKeyBase58Check,
      IsDAOCoin: true,
    });

    return data.BalanceEntry;
  }

  async toggleFollow(params: CreateFollowTxnStatelessRequest) {
    const { data } = await this.axiosClient.post('create-follow-txn-stateless', params);
    return data;
  }

  async updateProfile(params: Partial<UpdateProfileRequest>): Promise<UpdateProfileResponse> {
    const { data } = await this.axiosClient.post('update-profile', params);
    return data;
  }

  async GetTxn(TxnHashHex: string): Promise<GetTxnResponse> {
    const { data } = await this.axiosClient.post('get-txn', {
      TxnHashHex,
    });
    return data;
  }

  waitForTransaction(
    waitTxn: string = '',
    successCallback: (comp: any) => void = () => {},
    errorCallback: (comp: any) => void = () => {},
    comp: any = ''
  ) {
    // If we have a transaction to wait for, we do a GetTxn call for a maximum of 10s (250ms * 40).
    // There is a success and error callback so that the caller gets feedback on the polling.
    if (waitTxn !== '') {
      let attempts = 0;
      let numTries = 160;
      let timeoutMillis = 750;
      // Set an interval to repeat
      let interval = setInterval(() => {
        if (attempts >= numTries) {
          errorCallback(comp);
          clearInterval(interval);
        }
        this.GetTxn(waitTxn)
          .then(
            (res: any) => {
              if (!res.TxnFound) {
                return;
              }
              clearInterval(interval);
              successCallback(comp);
            },
            (error) => {
              clearInterval(interval);
              errorCallback(comp);
            }
          )
          .finally(() => attempts++);
      }, timeoutMillis) as any;
    }
  }

  async sendDeso(params: SendDeSoRequest) {
    const { data } = await this.axiosClient.post('send-deso', params);
    return data;
  }

  async submitPost(params: SubmitPostRequest) {
    const { data } = await this.axiosClient.post('submit-post', params);
    return data;
  }

  async createLikeStateless(params: CreateLikeStatelessRequest): Promise<CreateLikeStatelessResponse> {
    const { data } = await this.axiosClient.post('create-like-stateless', params);
    return data;
  }

  async sendDiamonds(params: SendDiamondsRequest): Promise<CreateLikeStatelessResponse> {
    const { data } = await this.axiosClient.post('send-diamonds', params);
    return data;
  }

  async submitTransaction(params: SubmitTransactionRequest) {
    const { data } = await this.axiosClient.post('submit-transaction', params);
    return data;
  }

  async getAllTokenLimitOrders(
    projectPublicKey: string,
    daoCoinPublicKey: string
  ): Promise<TokenLimitOrderEntryResponse[]> {
    const { data } = await this.axiosClient.post('get-dao-coin-limit-orders', {
      DAOCoin1CreatorPublicKeyBase58Check: projectPublicKey,
      DAOCoin2CreatorPublicKeyBase58Check: daoCoinPublicKey,
    });

    return data.Orders || [];
  }

  async getAllTransactorTokenLimitOrders(publicKeyBase58Check: string): Promise<TokenLimitOrderEntryResponse[]> {
    const { data } = await this.axiosClient.post('get-transactor-dao-coin-limit-orders', {
      TransactorPublicKeyBase58Check: publicKeyBase58Check,
    });

    return data.Orders;
  }

  async getFollowerCountByUsername(Username: string): Promise<number> {
    const { data } = await this.axiosClient.post('get-follows-stateless', {
      Username,
      GetEntriesFollowingUsername: true,
      LastPublicKeyBase58Check: '',
      NumToFetch: 0,
      PublicKeyBase58Check: '',
    });

    return data.NumFollowers;
  }

  async getFollowingCountByUsername(Username: string): Promise<number> {
    const { data } = await this.axiosClient.post('get-follows-stateless', {
      Username,
      GetEntriesFollowingUsername: false,
      LastPublicKeyBase58Check: '',
      NumToFetch: 0,
      PublicKeyBase58Check: '',
    });

    return data.NumFollowers;
  }

  async mintTokens(
    params: Pick<
      DAOCoinRequest,
      'CoinsToMintNanos' | 'UpdaterPublicKeyBase58Check' | 'ProfilePublicKeyBase58CheckOrUsername'
    >
  ): Promise<DAOCoinResponse> {
    const { data } = await this.axiosClient.post('dao-coin', {
      MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
      TransactionFees: null,
      OperationType: 'mint',
      ...params,
    });
    return data;
  }

  async burnTokens(
    params: Pick<
      DAOCoinRequest,
      'CoinsToBurnNanos' | 'UpdaterPublicKeyBase58Check' | 'ProfilePublicKeyBase58CheckOrUsername'
    >
  ): Promise<DAOCoinResponse> {
    const { data } = await this.axiosClient.post('dao-coin', {
      MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
      TransactionFees: null,
      OperationType: 'burn',
      ...params,
    });
    return data;
  }

  async changeTokenTransferRestrictionStatus(
    params: Pick<
      DAOCoinRequest,
      'TransferRestrictionStatus' | 'UpdaterPublicKeyBase58Check' | 'ProfilePublicKeyBase58CheckOrUsername'
    >
  ) {
    const { data } = await this.axiosClient.post('dao-coin', {
      MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
      TransactionFees: null,
      OperationType: 'update_transfer_restriction_status',
      ...params,
    });
    return data;
  }

  async disableProjectMinting(
    params: Pick<DAOCoinRequest, 'UpdaterPublicKeyBase58Check' | 'ProfilePublicKeyBase58CheckOrUsername'>
  ) {
    const { data } = await this.axiosClient.post('dao-coin', {
      MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
      TransactionFees: null,
      OperationType: 'disable_minting',
      ...params,
    });
    return data;
  }

  async transferToken(params: TransferDAOCoinRequest): Promise<TransferDAOCoinResponse> {
    const { data } = await this.axiosClient.post('transfer-dao-coin', params);
    return data;
  }

  async updateTutorialStatus(params: UpdateTutorialStatusRequest) {
    const { data } = await this.axiosClient.post('update-tutorial-status', params);
    return data;
  }

  async createTokenLimitOrder(params: TokenLimitOrderParams) {
    const { data } = await this.axiosClient.post('create-dao-coin-limit-order', params);
    return data;
  }

  async createTokenMarketOrder(params: TokenMarketOrderParams) {
    const { data } = await this.axiosClient.post('create-dao-coin-market-order', params);
    return data;
  }

  async cancelTokenLimitOrder(params: DAOCoinLimitOrderWithCancelOrderIDRequest) {
    const { data } = await this.axiosClient.post('cancel-dao-coin-limit-order', params);
    return data;
  }

  async getNFTsCreatedByUser(params: GetNFTsCreatedByPublicKeyRequest): Promise<GetNFTsCreatedByPublicKeyResponse> {
    const { data } = await this.axiosClient.post('get-nfts-created-by-public-key', params);
    return data;
  }

  async getTotalSupply() {
    const { data } = await this.axiosClient.get('total-supply');
    return data;
  }
}
