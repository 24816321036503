import { GetAppStateResponse, ProfileEntryResponse } from 'deso-protocol-types';
import { createContext } from 'react';
import { OpenfundUser } from 'services/Openfund';
import { GetExchangeRateUpdatedResponse } from '../services/NodeClient';

type IOpenfundContext = {
  currentUser: OpenfundUser | null;
  setCurrentUser: (currentUser: OpenfundUser | null) => void;
  exchangeRates: GetExchangeRateUpdatedResponse | null;
  desoAppState: GetAppStateResponse | null;
  usdProfileEntryResponse: ProfileEntryResponse | null;
};

export const OpenfundContext = createContext<IOpenfundContext>({
  currentUser: null,
  setCurrentUser: () => {},
  exchangeRates: null,
  desoAppState: null,
  usdProfileEntryResponse: null,
});
