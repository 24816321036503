import { getInteractiveElementClassName, InteractiveElementProps } from 'utils/getInteractiveElementClassName';

export function ExternalLink({
  kind = 'text-only-underline',
  size = 'md',
  shape = 'square',
  href,
  target,
  ...rest
}: InteractiveElementProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { className: extraClasses = '', children } = rest;
  const classes = getInteractiveElementClassName({ kind, size, shape, extraClasses, disabled: false });

  const props = {
    ...rest,
    className: classes,
    href: normalizeHref(href),
    target,
    rel: target === '_blank' ? 'noopener noreferrer' : rest.rel,
  };

  return <a {...props}>{children}</a>;
}

// Only allows urls with http(s?) or mailto: protocol
function normalizeHref(href: string | undefined) {
  if (!href) {
    return 'about:blank';
  }

  const protocol = href.match(/^\w+:\/?\/?/)?.[0];
  if (protocol === 'mailto:') {
    return href;
  }
  if (!protocol) {
    return `http://${href}`;
  }

  if (protocol.startsWith('http')) {
    return href;
  }

  return 'about:blank';
}
