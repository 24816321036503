import amplitude from 'amplitude-js';
import { AMPLITUDE_KEY, HEAP_KEY } from '../constants/AppConstants';

export function initializeTrackingOnce() {
  if (!(window as any).appHasLoaded && AMPLITUDE_KEY.length > 0) {
    amplitude.getInstance().init(AMPLITUDE_KEY);
    (window as any).heap.load(HEAP_KEY);

    trackingLogEvent('app : load', {});
    (window as any).appHasLoaded = true;
  }
}

export function trackingLogEvent(event: string, data: any = {}) {
  if (AMPLITUDE_KEY.length === 0) {
    console.log('trackingLogEvent->', event, data);
    return;
  }

  amplitude.getInstance().logEvent(event, data);
  (window as any).heap.track(event, data);
}

export function trackingIdentifyUser(pubkey: string, metadata: any) {
  if (AMPLITUDE_KEY.length === 0) {
    return;
  }

  // Identify in Amplitude
  amplitude.getInstance().setUserId(pubkey);
  amplitude.getInstance().setUserProperties(metadata);
  // Identify in Heap
  (window as any).heap.identify(pubkey);
  (window as any).heap.addUserProperties(metadata);
  // Identify in Smartlook
  (window as any).smartlook('identify', pubkey, metadata);
  // Identify in Hotjar
  (window as any).hj('identify', pubkey, metadata);
}
