import { Logo } from 'components/core/Logo';
import { Text } from 'components/core/Text';
import { Link } from 'react-router-dom';
import { Routes } from 'RoutePaths';

export function NotFound() {
  return (
    <div className="text-center mx-auto h-full flex flex-col justify-center">
      <div className="w-3/4 lg:w-1/2 mx-auto">
        <Link to={Routes.home()}>
          <Logo width="100%" />
        </Link>
      </div>
      <Text size="lg">This is not the page you are looking for...</Text>
    </div>
  );
}
