const SIZE_MAP: Record<string, string> = Object.freeze({
  sm: 'font-bold text-md lg:text-lg',
  md: 'font-bold text-xl lg:text-3xl',
  lg: 'font-bold text-2xl lg:text-4xl',
});

type HeadingProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  size?: 'sm' | 'md' | 'lg';
};
export function Heading({
  level,
  size = 'md',
  className = '',
  children,
}: HeadingProps & React.HTMLAttributes<HTMLHeadingElement>) {
  const baseClasses = `${SIZE_MAP[size]}`;
  const classes = className ? `${className} ${baseClasses}` : baseClasses;
  const HeadingEl = `h${level}` as keyof JSX.IntrinsicElements;

  return <HeadingEl className={`${classes}`}>{children}</HeadingEl>;
}
