import {
  MIN_FEE_RATE_NANOS_PER_KB,
  MIN_FEE_RATE_NANOS_PER_KB as MIN_TX_FEE_RATE_NANOS_PER_KB,
  NODE_URL,
} from 'constants/AppConstants';
import { Deso as DesoClient } from 'deso-protocol';
import {
  BlockPublicKeyResponse,
  CreateLikeStatelessRequest,
  DAOCoinLimitOrderWithCancelOrderIDRequest,
  GetFollowsResponse,
  GetPostsForPublicKeyResponse,
  GetPostsStatelessRequest,
  GetSinglePostResponse,
  HotFeedPageRequest,
  HotFeedPageResponse,
  ProfileEntryResponse,
  SendDiamondsRequest,
  SubmitPostRequest,
  UpdateProfileRequest,
  UpdateProfileResponse,
} from 'deso-protocol-types';
import { DesoIdentity, PublicKey } from './Identity';
import { TokenLimitOrderParams, TokenMarketOrderParams, NodeClient } from './NodeClient';
import axios from 'axios';

export const ProjectPublicKeysPurchasedKey = 'DAOPublicKeysPurchased';

export class Deso {
  private node: NodeClient;
  private identity: DesoIdentity;
  private transactionQueue: Function[] = [];
  private transactionProcessing: boolean = false;

  // TODO: We should probably be using the deso-protocol library wherever we can.
  private desoClient: DesoClient;

  constructor(node: NodeClient, identity: DesoIdentity) {
    this.node = node;
    this.identity = identity;
    this.desoClient = new DesoClient({ nodeUri: NODE_URL });
  }

  async uploadImage(file: File): Promise<string> {
    const jwt = await this.identity.getJWT();
    return this.node.uploadImage({
      jwt,
      publicKey: this.identity.loggedInPublicKey,
      file: file,
    });
  }

  async uploadVideo(file: File): Promise<{ asset: { id: string; playbackId: string } }> {
    const jwt = await this.identity.getJWT();
    const body = new FormData();
    body.append('file', file);
    body.append('UserPublicKeyBase58Check', this.identity.loggedInPublicKey);
    body.append('JWT', jwt);
    return axios.post('https://media.deso.org/api/v0/upload-video', body).then((res) => res.data);
  }

  async getVideoStatus(videoId: string): Promise<{ status: { phase: string } }> {
    return axios.get(`https://media.deso.org/api/v0/get-video-status/${videoId}`).then((res) => res.data);
  }

  async getFollows(
    Username: string,
    GetEntriesFollowingUsername: boolean,
    LastPublicKeyBase58Check: string,
    NumToFetch: number = 20
  ): Promise<GetFollowsResponse> {
    return this.desoClient.social.getFollowsStateless({
      Username,
      GetEntriesFollowingUsername,
      LastPublicKeyBase58Check,
      NumToFetch,
    });
  }

  async addProjectPurchasedToProfile(purchasedProjectPublicKey: string): Promise<UpdateProfileResponse | null> {
    if (purchasedProjectPublicKey === 'DESO') {
      return null;
    }
    const userStatelessResponse = await this.desoClient.user.getUserStateless({
      PublicKeysBase58Check: [this.identity.loggedInPublicKey],
    });
    if (!userStatelessResponse?.UserList?.length) {
      return null;
    }
    const user = userStatelessResponse.UserList[0];
    if (!user?.ProfileEntryResponse?.Username) {
      return null;
    }
    let projectsPurchased = '';
    if (user.ProfileEntryResponse.ExtraData && user.ProfileEntryResponse.ExtraData[ProjectPublicKeysPurchasedKey]) {
      projectsPurchased = user.ProfileEntryResponse.ExtraData[ProjectPublicKeysPurchasedKey];
    }
    // This public key has already been purchased, don't need to readd to list.
    if (projectsPurchased.split(',').includes(purchasedProjectPublicKey)) {
      return null;
    }

    projectsPurchased = projectsPurchased.length
      ? `${projectsPurchased},${purchasedProjectPublicKey}`
      : purchasedProjectPublicKey;
    return this.updateProfile({
      NewCreatorBasisPoints: user.ProfileEntryResponse.CoinEntry?.CreatorBasisPoints,
      NewStakeMultipleBasisPoints: 1.25 * 100 * 100,
      ExtraData: {
        [ProjectPublicKeysPurchasedKey]: projectsPurchased,
      },
    });
  }

  async updateProfile(request: Partial<UpdateProfileRequest>): Promise<UpdateProfileResponse> {
    const tx = await this.node.updateProfile({
      ...request,
      ...{
        MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
        UpdaterPublicKeyBase58Check: this.identity.loggedInPublicKey,
        ProfilePublicKeyBase58Check: this.identity.loggedInPublicKey,
      },
    });
    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async blockUser(BlockPublicKeyBase58Check: string, Unblock: boolean = false): Promise<BlockPublicKeyResponse> {
    const jwt = await this.identity.getJWT();
    return this.desoClient.user.blockPublicKey({
      PublicKeyBase58Check: this.identity.loggedInPublicKey,
      BlockPublicKeyBase58Check,
      Unblock,
      JWT: jwt,
    });
  }

  profilePicUrl(profilePublicKey: PublicKey = '') {
    return this.node.getProfilePicUrl(profilePublicKey);
  }

  async getUSDPerDesoExchangeRate() {
    const { USDCentsPerDeSoCoinbase } = await this.node.getExchangeRate();
    return Number((USDCentsPerDeSoCoinbase / 100).toFixed(2));
  }

  follow(FollowedPublicKeyBase58Check: string) {
    // Add to transaction queue
    // During callback, recursively call next function
    const funcParam = () => this.toggleFollow(FollowedPublicKeyBase58Check, false);
    return this.queueTransaction(funcParam);
  }

  // This function gets called after a transaction gets confirmed.
  // It checks to see if there are any outstanding transactions, and calls them.
  async queuedTxnCallback(comp: any) {
    if (comp.transactionQueue.length > 0) {
      setTimeout(async () => {
        const nextFunction = comp.transactionQueue.pop();
        const txn = await nextFunction();
        const txnHashHex = txn.TxnHashHex;
        return comp.node.waitForTransaction(txnHashHex, comp.queuedTxnCallback, comp.queuedTxnErrorCallback, comp);
      }, 200);
    } else {
      comp.transactionProcessing = false;
    }
  }

  queuedTxnErrorCallback(comp: any) {
    console.log('Error during callback');
  }

  async queueTransaction(f: Function) {
    // If there isn't anything currently processing, we execute the function and await the completion
    if (!this.transactionProcessing) {
      this.transactionProcessing = true;
      const txn = await f();
      const txnHashHex = txn.TxnHashHex;
      this.node.waitForTransaction(txnHashHex, this.queuedTxnCallback, this.queuedTxnErrorCallback, this);
    } else {
      // If there is something currently being waited for, we push the next transaction to the queue.
      this.transactionQueue.push(f);
    }
  }

  unfollow(FollowedPublicKeyBase58Check: string) {
    return this.toggleFollow(FollowedPublicKeyBase58Check, true);
  }

  async getPostsForPublicKey(
    publicKey: string,
    lastPostHashHex: string,
    numToFetch: number,
    username: string = ''
  ): Promise<GetPostsForPublicKeyResponse> {
    return this.desoClient.posts.getPostsForPublicKey({
      PublicKeyBase58Check: publicKey,
      Username: username,
      LastPostHashHex: lastPostHashHex,
      NumToFetch: numToFetch,
      ReaderPublicKeyBase58Check: this.identity.loggedInPublicKey,
    });
  }

  async getSinglePost(
    PostHashHex: string,
    options: {
      FetchParents?: boolean;
      CommentOffset?: number;
      CommentLimit?: number;
      ThreadLevelLimit?: number;
      ThreadLeafLimit?: number;
      LoadAuthorThread?: boolean;
      AddGlobalFeedBool?: boolean;
    } = {
      FetchParents: false,
      CommentOffset: 0,
      CommentLimit: 10,
      ThreadLevelLimit: 0,
      ThreadLeafLimit: 0,
      LoadAuthorThread: false,
      AddGlobalFeedBool: false,
    }
  ): Promise<GetSinglePostResponse> {
    return this.desoClient.posts.getSinglePost({
      PostHashHex,
      ReaderPublicKeyBase58Check: this.identity.loggedInPublicKey,
      ...options,
    });
  }

  async tyTestMethod(
    seenPosts: string[] = [],
    responseLimit: number = 20,
    tag: string = '',
    sortByNew: boolean = false
  ): Promise<void> {
    const requestHotFeed: HotFeedPageRequest = {
      ReaderPublicKeyBase58Check: this.identity.loggedInPublicKey,
      SeenPosts: seenPosts,
      Tag: tag,
      SortByNew: sortByNew,
      ResponseLimit: responseLimit,
    };
    this.desoClient.posts.getHotFeed(requestHotFeed);
    const request: Partial<GetPostsStatelessRequest> = {};

    this.desoClient.posts.getPostsForPublicKey(request);
  }

  async getHotFeed(
    seenPosts: string[] = [],
    responseLimit: number = 20,
    tag: string = '',
    sortByNew: boolean = false
  ): Promise<HotFeedPageResponse> {
    const request: HotFeedPageRequest = {
      ReaderPublicKeyBase58Check: this.identity.loggedInPublicKey,
      SeenPosts: seenPosts,
      Tag: tag,
      SortByNew: sortByNew,
      ResponseLimit: responseLimit,
    };
    return this.desoClient.posts.getHotFeed(request).then((res) => {
      res.HotFeedPage = res.HotFeedPage?.filter(({ IsHidden }) => !IsHidden) ?? null;
      return res;
    });
  }

  async sendDeso(SenderPublicKeyBase58Check: string, RecipientPublicKeyOrUsername: string, AmountNanos: number) {
    const tx = await this.node.sendDeso({
      SenderPublicKeyBase58Check,
      RecipientPublicKeyOrUsername,
      AmountNanos,
      MinFeeRateNanosPerKB: MIN_FEE_RATE_NANOS_PER_KB,
      TransactionFees: null,
    });

    return this.signAndSubmitTx(tx.TransactionHex, SenderPublicKeyBase58Check);
  }

  async createPost(params: SubmitPostRequest) {
    const tx = await this.node.submitPost(params);
    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async likePost(params: CreateLikeStatelessRequest) {
    const tx = await this.node.createLikeStateless(params);
    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async diamondPost(params: SendDiamondsRequest) {
    const tx = await this.node.sendDiamonds(params);
    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async mintMyTokens(tokensToMintBaseUnits: string) {
    const publicKey = this.identity.loggedInPublicKey;

    const tx = await this.node.mintTokens({
      CoinsToMintNanos: tokensToMintBaseUnits,
      UpdaterPublicKeyBase58Check: publicKey,
      ProfilePublicKeyBase58CheckOrUsername: publicKey,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async burnTokens(tokensToBurnBaseUnits: string, projectPublicKey: string) {
    const publicKey = this.identity.loggedInPublicKey;

    const tx = await this.node.burnTokens({
      CoinsToBurnNanos: tokensToBurnBaseUnits,
      UpdaterPublicKeyBase58Check: publicKey,
      ProfilePublicKeyBase58CheckOrUsername: projectPublicKey,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async changeMyTokenTransferRestrictionStatus(TransferRestrictionStatus: string) {
    const publicKey = this.identity.loggedInPublicKey;

    const tx = await this.node.changeTokenTransferRestrictionStatus({
      TransferRestrictionStatus,
      UpdaterPublicKeyBase58Check: publicKey,
      ProfilePublicKeyBase58CheckOrUsername: publicKey,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async disableMintingMyToken() {
    const publicKey = this.identity.loggedInPublicKey;

    const tx = await this.node.disableProjectMinting({
      UpdaterPublicKeyBase58Check: publicKey,
      ProfilePublicKeyBase58CheckOrUsername: publicKey,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async transferTokens(projectIdentifier: string, receiverIdentifier: string, amountTokenBaseUnitsHex: string) {
    const SenderPublicKeyBase58Check = this.identity.loggedInPublicKey;

    const tx = await this.node.transferToken({
      SenderPublicKeyBase58Check,
      ProfilePublicKeyBase58CheckOrUsername: projectIdentifier,
      ReceiverPublicKeyBase58CheckOrUsername: receiverIdentifier,
      DAOCoinToTransferNanos: amountTokenBaseUnitsHex,
      MinFeeRateNanosPerKB: MIN_TX_FEE_RATE_NANOS_PER_KB,
      TransactionFees: null,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async setTutorialStatusComplete() {
    const PublicKeyBase58Check = this.identity.loggedInPublicKey;

    if (!PublicKeyBase58Check) {
      throw new Error('Cannot update tutorial status without a logged in user.');
    }

    return this.node.updateTutorialStatus({
      PublicKeyBase58Check,
      JWT: await this.identity.getJWT(),
      TutorialStatus: 'TutorialComplete',
      CreatorPurchasedInTutorialPublicKey: '',
      ClearCreatorCoinPurchasedInTutorial: false,
    });
  }

  async createTokenLimitOrder(
    params: Pick<
      TokenLimitOrderParams,
      | 'BuyingDAOCoinCreatorPublicKeyBase58Check'
      | 'SellingDAOCoinCreatorPublicKeyBase58Check'
      | 'Price'
      | 'Quantity'
      | 'OperationType'
      | 'FillType'
    >
  ) {
    const tx = await this.simulateTokenLimitOrder({
      BuyingDAOCoinCreatorPublicKeyBase58Check: params.BuyingDAOCoinCreatorPublicKeyBase58Check,
      SellingDAOCoinCreatorPublicKeyBase58Check: params.SellingDAOCoinCreatorPublicKeyBase58Check,
      Price: params.Price,
      Quantity: params.Quantity,
      OperationType: params.OperationType,
      FillType: params.FillType,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async simulateTokenLimitOrder(
    params: Pick<
      TokenLimitOrderParams,
      | 'BuyingDAOCoinCreatorPublicKeyBase58Check'
      | 'SellingDAOCoinCreatorPublicKeyBase58Check'
      | 'Price'
      | 'Quantity'
      | 'OperationType'
      | 'FillType'
    >
  ) {
    const TransactorPublicKeyBase58Check = this.identity.loggedInPublicKey;

    if (!TransactorPublicKeyBase58Check) {
      throw new Error('Cannot create a limit order without a logged in user.');
    }

    return this.node.createTokenLimitOrder({
      TransactorPublicKeyBase58Check,
      BuyingDAOCoinCreatorPublicKeyBase58Check: params.BuyingDAOCoinCreatorPublicKeyBase58Check,
      SellingDAOCoinCreatorPublicKeyBase58Check: params.SellingDAOCoinCreatorPublicKeyBase58Check,
      Price: params.Price,
      Quantity: params.Quantity,
      OperationType: params.OperationType,
      FillType: params.FillType,
      MinFeeRateNanosPerKB: MIN_TX_FEE_RATE_NANOS_PER_KB,
    });
  }

  async createTokenMarketOrder(
    params: Pick<
      TokenMarketOrderParams,
      | 'OperationType'
      | 'BuyingDAOCoinCreatorPublicKeyBase58Check'
      | 'SellingDAOCoinCreatorPublicKeyBase58Check'
      | 'Quantity'
      | 'FillType'
    >
  ) {
    const tx = await this.simulateTokenMarketOrder({
      OperationType: params.OperationType,
      BuyingDAOCoinCreatorPublicKeyBase58Check: params.BuyingDAOCoinCreatorPublicKeyBase58Check,
      SellingDAOCoinCreatorPublicKeyBase58Check: params.SellingDAOCoinCreatorPublicKeyBase58Check,
      Quantity: params.Quantity,
      FillType: params.FillType,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  async simulateTokenMarketOrder(
    params: Pick<
      TokenMarketOrderParams,
      | 'OperationType'
      | 'BuyingDAOCoinCreatorPublicKeyBase58Check'
      | 'SellingDAOCoinCreatorPublicKeyBase58Check'
      | 'Quantity'
      | 'FillType'
    >
  ) {
    const TransactorPublicKeyBase58Check = this.identity.loggedInPublicKey;

    if (!TransactorPublicKeyBase58Check) {
      throw new Error('Cannot create a market order without a logged in user.');
    }

    return this.node.createTokenMarketOrder({
      OperationType: params.OperationType,
      TransactorPublicKeyBase58Check,
      BuyingDAOCoinCreatorPublicKeyBase58Check: params.BuyingDAOCoinCreatorPublicKeyBase58Check,
      SellingDAOCoinCreatorPublicKeyBase58Check: params.SellingDAOCoinCreatorPublicKeyBase58Check,
      Quantity: params.Quantity,
      FillType: params.FillType,
      MinFeeRateNanosPerKB: MIN_TX_FEE_RATE_NANOS_PER_KB,
    });
  }

  async cancelTokenLimitOrder(params: Pick<DAOCoinLimitOrderWithCancelOrderIDRequest, 'CancelOrderID'>) {
    const TransactorPublicKeyBase58Check = this.identity.loggedInPublicKey;

    if (!TransactorPublicKeyBase58Check) {
      throw new Error('Cannot cancel a limit order without a logged in user.');
    }
    const tx = await this.node.cancelTokenLimitOrder({
      TransactorPublicKeyBase58Check,
      CancelOrderID: params.CancelOrderID,
      MinFeeRateNanosPerKB: MIN_TX_FEE_RATE_NANOS_PER_KB,
      TransactionFees: null,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  private async toggleFollow(FollowedPublicKeyBase58Check: string, IsUnfollow: boolean) {
    const loggedInPublicKey = this.identity.loggedInPublicKey;

    if (!loggedInPublicKey) {
      throw new Error('Attempted to toggle following status but there is not a logged in user');
    }

    const tx = await this.node.toggleFollow({
      IsUnfollow,
      FollowedPublicKeyBase58Check,
      FollowerPublicKeyBase58Check: loggedInPublicKey,
      MinFeeRateNanosPerKB: MIN_TX_FEE_RATE_NANOS_PER_KB,
      TransactionFees: null,
    });

    return this.signAndSubmitTx(tx.TransactionHex);
  }

  private async signAndSubmitTx(txHex: string, signerKey?: string) {
    return this.node.submitTransaction({
      TransactionHex: await this.identity.signTx(txHex, signerKey),
    });
  }
}
