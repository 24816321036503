import { SiteHeader } from 'components/layout/SiteHeader';
import { Outlet, useLocation } from 'react-router-dom';
import { SiteFooter } from 'components/layout/SiteFooter';
import { useAppContext } from 'components/App';
import { classNames } from 'utils/classNames';
import { Routes } from 'RoutePaths';

export function SiteContainer() {
  const { pathname } = useLocation();
  return (
    <div className="overflow-x-hidden lg:overflow-inherit h-full">
      <SiteHeader />
      <div
        role="main"
        tabIndex={-1}
        className={classNames(pathname !== Routes.activity() && 'px-4 lg:mt-8', 'lg:mt-0 focus:outline-none')}
      >
        <Outlet context={useAppContext()} />
      </div>
      <SiteFooter />
    </div>
  );
}
