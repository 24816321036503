import { Button } from 'components/core/Button';
import { Modal } from 'components/core/Modal';
import { OpenfundContext } from 'contexts/Openfund';
import { useToast } from 'hooks/useToast';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { openfund } from 'services';
import { InteractiveElementProps } from 'utils/getInteractiveElementClassName';
import { Spinner } from 'components/core/Spinner';
import { getErrorMsg } from 'utils/getErrorMsg';
import { OpenfundUser } from 'services/Openfund';

export type OpenfundGetStartedButton = {
  buttontype: string;
};

export function StartProjectButton(
  props: InteractiveElementProps & React.ButtonHTMLAttributes<HTMLButtonElement> & OpenfundGetStartedButton
) {
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const toast = useToast();
  const navigate = useNavigate();
  const [isNoMoneyErrorModalOpen, setIsNoMoneyErrorModalOpen] = useState(false);
  const [isProjectSettingsModalOpen, setIsProjectSettingsModalOpen] = useState(false);
  const [localAuthUser, setLocalAuthUser] = useState<OpenfundUser | null>(currentUser);
  const [isExistingProfileModalOpen, setIsExistingProfileModalOpen] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  let needMoneyModal = (
    <Modal
      isOpen={isNoMoneyErrorModalOpen}
      onClose={() => setIsNoMoneyErrorModalOpen(false)}
      title="You need some $DESO to get started!"
      description="The account you selected needs a small amount $DESO to get started. Try logging in again and either buying $DESO or entering your phone number for free starter $DESO."
      footer={
        <div className="text-center">
          <Button
            onClick={async () => {
              try {
                setIsNoMoneyErrorModalOpen(false);
              } catch (e) {
                toast.show({
                  message: getErrorMsg(e),
                  type: 'error',
                });
              }
            }}
          >
            OK
          </Button>
        </div>
      }
    />
  );

  let projectSettingsModal = (
    <Modal
      isOpen={isProjectSettingsModalOpen}
      onClose={() => setIsProjectSettingsModalOpen(false)}
      title="Message"
      description="The account you selected already has a fundraising round."
      footer={
        <div className="text-center">
          <Button
            kind="btn-primary"
            onClick={() => {
              setCurrentUser(localAuthUser);
              navigate(Routes.fund(localAuthUser?.ProfileEntryResponse?.Username ?? ''));
            }}
          >
            Go to Project
          </Button>
        </div>
      }
    />
  );

  let onButtonClick = async () => {
    try {
      let authUser = await openfund.login();
      setIsWaiting(true);
      setLocalAuthUser(authUser);

      if (!authUser) {
        toast.show({ message: 'There was a problem logging in. Please try again.', type: 'error' });
        setIsWaiting(false);
        return;
      }

      if (props.buttontype === 'createProject' && authUser.isProjectOwner) {
        setIsProjectSettingsModalOpen(true);
        setIsWaiting(false);
        return;
      }
      if (props.buttontype === 'createAccount' && !!authUser.ProfileEntryResponse?.Username) {
        setIsExistingProfileModalOpen(true);
        setIsWaiting(false);
        setCurrentUser(authUser);
        return;
      }

      setCurrentUser(authUser);
      setIsWaiting(false);

      if (props.buttontype === 'createAccount') {
        navigate(Routes.userOnboardingEmail());
      } else {
        navigate(Routes.projectOnboarding());
      }
    } catch (e) {
      setIsWaiting(false);
      toast.show({ message: getErrorMsg(e), type: 'error' });
    }
  };

  let startProjectElement = (
    <>
      {needMoneyModal}
      {projectSettingsModal}
      <Button size={props.size ?? 'lg'} shape="rounded" kind={props.kind ?? 'btn-primary'} onClick={onButtonClick}>
        Start your project
      </Button>
    </>
  );

  let existingProfileModal = (
    <Modal
      size="sm"
      isOpen={isExistingProfileModalOpen}
      onClose={() => setIsExistingProfileModalOpen(false)}
      title="Message"
      description="The account you selected already has a profile."
      footer={
        <div className="text-center">
          <Button
            kind="btn-primary"
            onClick={() => {
              navigate(Routes.activity());
              setIsExistingProfileModalOpen(false);
            }}
          >
            Go to Feed
          </Button>
        </div>
      }
    />
  );

  let createProfileButton = (
    <Button
      size={props.size ?? 'lg'}
      kind="btn-tertiary-light"
      shape="rounded"
      className="mr-0 mb-4 md:mb-0 md:mr-2 bg-black"
      onClick={onButtonClick}
    >
      Create Wallet
    </Button>
  );

  let createAccountElement = (
    <>
      {needMoneyModal}
      {existingProfileModal}
      {createProfileButton}
    </>
  );

  let waitingSpinner = (
    <>
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'black',
          opacity: '50%',
          width: '100%',
          height: '100%',
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className="text-center py-8"
      >
        <Spinner />
      </div>
    </>
  );

  let renderedElement = startProjectElement;
  if (props.buttontype === 'createAccount') {
    renderedElement = createAccountElement;
  }

  if (isWaiting) {
    return (
      <>
        {renderedElement}
        {waitingSpinner}
      </>
    );
  } else {
    return renderedElement;
  }
}
