import { Avatar } from 'components/core/Avatar';
import { Button } from 'components/core/Button';
import { openfund, deso } from 'services';
import { useToast } from 'hooks/useToast';
import { NavLink, useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { useContext, useState, Fragment, useEffect } from 'react';
import { OpenfundContext } from 'contexts/Openfund';
import { getErrorMsg } from 'utils/getErrorMsg';
import { LoadingSpinner } from 'components/core/LoadingSpinner';
import { IoSettingsOutline } from '@react-icons/all-files/io5/IoSettingsOutline';
import { IoWalletOutline } from '@react-icons/all-files/io5/IoWalletOutline';
import { IoPowerOutline } from '@react-icons/all-files/io5/IoPowerOutline';
import { IoSwapVerticalSharp } from '@react-icons/all-files/io5/IoSwapVerticalSharp';
import { IoPersonAddOutline } from '@react-icons/all-files/io5/IoPersonAddOutline';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Menu } from '@headlessui/react';
import { classNames } from 'utils/classNames';
import { Transition } from '@headlessui/react';
import { v4 as uuid } from 'uuid';
import { IoMailOutline } from '@react-icons/all-files/io5/IoMailOutline';
import { StartProjectButton } from 'components/app-ui/StartProjectButton';

const MENU_TRIGGER_ID = uuid();
const COMMON_MENU_ITEM_CLASSES = 'flex items-center w-full px-6 lg:px-4 py-2';
const COMMON_MENU_ITEM_ACTIVE_CLASSES = 'bg-gray-eee text-black';
const getMenuItemClassNames = (active: boolean) =>
  classNames(COMMON_MENU_ITEM_CLASSES, active && COMMON_MENU_ITEM_ACTIVE_CLASSES);

export function ProfileNavItem({ className }: React.HTMLAttributes<HTMLElement>) {
  const dropdownTriggerId = 'site-nave-profile-dropdown-trigger';
  const loginButtonId = 'site-nav-login-btn';
  const { currentUser, setCurrentUser } = useContext(OpenfundContext);
  const setFocusToDropdownTrigger = () => setTimeout(() => document.getElementById(dropdownTriggerId)?.focus(), 1);
  const toast = useToast();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [mailToAttr, setMailToAttr] = useState<string>(getHelpMailToAttr());

  const login = async () => {
    try {
      const users = await openfund.login(() => setIsPending(true));
      setCurrentUser(users);
      setFocusToDropdownTrigger();
    } catch (e) {
      toast.show({
        message: getErrorMsg(e),
        type: 'error',
      });
    }
    setIsPending(false);
  };

  function getHelpMailToAttr(): string {
    const bodyContent = currentUser?.PublicKeyBase58Check
      ? encodeURIComponent(
          `The below information helps support address your case.\nMy public key: ${currentUser.PublicKeyBase58Check}`
        )
      : '';
    return `mailto:support@deso.org${bodyContent ? `?body=${bodyContent}` : ''}`;
  }

  useEffect(() => {
    setMailToAttr(getHelpMailToAttr());
  }, [currentUser?.PublicKeyBase58Check]);

  if (isPending) {
    return (
      <div className={className}>
        <LoadingSpinner className="h-8 w-8" />
      </div>
    );
  }

  if (!currentUser) {
    return (
      <Button id={loginButtonId} className={className} onClick={login} kind="text-only">
        Login
      </Button>
    );
  }

  return (
    <>
      <Menu as="div" className={classNames(className, 'lg:relative w-8')}>
        <Menu.Button>
          <span id={MENU_TRIGGER_ID}>
            {currentUser?.ProfileEntryResponse ? (
              <Avatar
                className="bg-gray-faint"
                src={deso.profilePicUrl(currentUser.ProfileEntryResponse.PublicKeyBase58Check)}
                alt={currentUser.ProfileEntryResponse.Username}
              />
            ) : (
              <Avatar alt="Anonymous User" className="bg-gray-faint" />
            )}
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transform transition ease-in-out lg:ease-out duration-700 lg:duration-100"
          enterFrom="translate-x-full lg:translate-x-0 lg:transform lg:opacity-0 lg:scale-95"
          enterTo="translate-x-0 lg:transform lg:opacity-100 lg:scale-100"
          leave="transform transition ease-in-out lg:ease-in duration:700 lg:duration-75"
          leaveFrom="translate-x-0 lg:transform lg:opacity-100 lg:scale-100"
          leaveTo="translate-x-full lg:translate-x-0 lg:transform lg:opacity-0 lg:scale-95"
        >
          <Menu.Items className="fixed top-0 lg:top-auto right-0 lg:absolute overflow-hidden lg:mt-1 w-screen lg:w-80 h-screen lg:h-auto origin-top-right lg:rounded-md bg-white ring-1 ring-blue-dark focus:outline-none">
            <div className="text-gray-dark">
              <div className="lg:hidden">
                <button
                  className="p-2"
                  onClick={() => {
                    document.getElementById(MENU_TRIGGER_ID)?.click();
                  }}
                >
                  <IoClose className="h-6 w-6" />
                </button>
              </div>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={getMenuItemClassNames(active)}
                    onClick={() => {
                      if (currentUser.isProjectOwner && currentUser.ProfileEntryResponse?.Username) {
                        navigate(Routes.fund(currentUser.ProfileEntryResponse?.Username));
                      } else if (currentUser.ProfileEntryResponse) {
                        navigate(Routes.profile(currentUser.ProfileEntryResponse.Username));
                      } else {
                        navigate(Routes.settingsProfile());
                      }
                    }}
                  >
                    <Avatar
                      src={deso.profilePicUrl(currentUser.PublicKeyBase58Check)}
                      className="flex-shrink-0 h-12 w-12"
                    />
                    <div className="ml-4 overflow-hidden text-left">
                      <div
                        className="text-md font-bold truncate"
                        title={currentUser.ProfileEntryResponse?.Username ?? currentUser.PublicKeyBase58Check}
                      >
                        {currentUser.ProfileEntryResponse?.Username ?? currentUser.PublicKeyBase58Check}
                      </div>
                      <div className="text-sm">
                        {currentUser.ProfileEntryResponse ? 'View profile' : 'Update profile'}
                      </div>
                    </div>
                  </button>
                )}
              </Menu.Item>
              <div className="border-b border-gray-faint w-5/6 ml-4"></div>
              <Menu.Item>
                {({ active }) => (
                  <NavLink to={Routes.settingsProfile()} className={getMenuItemClassNames(active)}>
                    <IoSettingsOutline className="mr-2" /> My Settings
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <NavLink to={Routes.wallet()} className={getMenuItemClassNames(active)}>
                    <IoWalletOutline className="mr-2" /> My Wallet
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a className={getMenuItemClassNames(active)} href={mailToAttr}>
                    <IoMailOutline className="mr-2" /> Help
                  </a>
                )}
              </Menu.Item>
              <div className="border-b border-gray-faint w-5/6 ml-4"></div>
              <Menu.Item>
                {({ active }) => (
                  <button className={getMenuItemClassNames(active)} onClick={login}>
                    <IoSwapVerticalSharp className="mr-2" /> Switch Account
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button className={getMenuItemClassNames(active)} onClick={login}>
                    <IoPersonAddOutline className="mr-2" /> Create Account
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={getMenuItemClassNames(active)}
                    onClick={async () => {
                      try {
                        await openfund.logout(() => setIsPending(true));
                        setCurrentUser(null);
                        setTimeout(() => document.getElementById(loginButtonId)?.focus(), 1);
                      } catch (e) {
                        toast.show({
                          message: getErrorMsg(e),
                          type: 'error',
                        });
                      }
                      setIsPending(false);
                    }}
                  >
                    <IoPowerOutline className="mr-2" /> Logout
                  </button>
                )}
              </Menu.Item>
              {!currentUser.isProjectOwner && (
                <div className="pl-4 mt-4 lg:mb-4">
                  <StartProjectButton buttontype="createProject" size="sm" shape="rounded" />
                </div>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
