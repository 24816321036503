import { IoHelpCircleOutline } from '@react-icons/all-files/io5/IoHelpCircleOutline';
import { IoNotificationsOutline } from '@react-icons/all-files/io5/IoNotificationsOutline';
import { IoSettingsOutline } from '@react-icons/all-files/io5/IoSettingsOutline';
import { ProfileNavItem } from 'components/app-ui/ProfileNavItem';
import { StartProjectButton } from 'components/app-ui/StartProjectButton';
import { Logo } from 'components/core/Logo';
import { RouteLink } from 'components/core/RouteLink';
import { OpenfundContext } from 'contexts/Openfund';
import { useIsMounted } from 'hooks/useIsMounted';
import { useToast } from 'hooks/useToast';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'RoutePaths';
import { openfund, deso, identity, nodeClient } from 'services';
import { classNames } from 'utils/classNames';
import { getErrorMsg } from 'utils/getErrorMsg';
import { SearchInput, SearchMenuItem } from '../app-ui/SearchInput';
import { Avatar } from '../core/Avatar';
import { ExternalLink } from '../core/ExternalLink';
import { getWrappedAsset } from '../../utils/deso';

export function SiteHeader() {
  const { currentUser } = useContext(OpenfundContext);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [isSearchingOnMobile, setIsSearchingOnMobile] = useState(false);
  const [mailToAttr, setMailToAttr] = useState<string>(getHelpMailToAttr());
  const isMounted = useIsMounted();
  const toast = useToast();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  function getHelpMailToAttr(): string {
    const bodyContent = currentUser?.PublicKeyBase58Check
      ? encodeURIComponent(
          `The below information helps support address your case.\nMy public key: ${currentUser.PublicKeyBase58Check}`
        )
      : '';
    return `mailto:support@deso.org${bodyContent ? `?body=${bodyContent}` : ''}`;
  }

  useEffect(() => {
    if (currentUser && pathname !== Routes.notifications()) {
      openfund
        .getNotifications(currentUser.PublicKeyBase58Check, 0, 30, [])
        .then((res) => {
          if (isMounted) {
            setNotificationsCount(res.NotificationCounts.UnreadCount);
          }
        })
        .catch((e) => {
          toast.show({ message: getErrorMsg(e), type: 'error' });
        });
    } else {
      // clear notifications when visiting the notifications page it will get
      // re-fetched when the user navigates to another page.
      setNotificationsCount(0);
    }
  }, [currentUser, pathname]);

  useEffect(() => {
    setMailToAttr(getHelpMailToAttr());
  }, [currentUser?.PublicKeyBase58Check]);

  let notificationCountsSpacing = 'pr-1 mr-5';
  if (notificationsCount > 9) {
    notificationCountsSpacing = 'pr-3 mr-4';
  } else if (notificationsCount > 99) {
    notificationCountsSpacing = 'pr-5 mr-3';
  }

  const onSearchItemSelected = (item: SearchMenuItem | null) => {
    if (item) {
      const wrappedAsset = getWrappedAsset(item.inputDisplayValue, 'displayName');
      const isWrapped = !!wrappedAsset;
      isWrapped ? navigate(Routes.profile(wrappedAsset.displayName)) : navigate(Routes.fund(item.inputDisplayValue));
    }
  };

  const onSearchItemQuery = async (query: string) => {
    const res = await nodeClient.getProfilesByUsername(query, identity.loggedInPublicKey, 15);
    return res.map((p) => ({
      id: p.PublicKeyBase58Check,
      inputDisplayValue: p.Username,
      menuItemContent: (
        <div className="flex items-center cursor-pointer">
          <Avatar src={deso.profilePicUrl(p.PublicKeyBase58Check)} className="bg-gray-faint" />
          <span className="ml-4">{p.Username}</span>
        </div>
      ),
    }));
  };

  return (
    <div
      id="site-header"
      role="banner"
      className="absolute top-0 bg-gray-charcoal/80 backdrop-blur-[4px] w-full z-50 border-b border-b-gray-333"
    >
      <div className="flex max-w-7xl m-auto w-full lg:flex lg:flex-row items-center p-3">
        <div className="lg:hidden flex items-center pt-2 w-full">
          {!isSearchingOnMobile && (
            <Link to={Routes.home()} className="mr-4">
              <Logo />
            </Link>
          )}
          <SearchInput
            className="mr-4 w-full"
            placeholder="Search"
            theme="dark"
            size="sm"
            onItemSelected={onSearchItemSelected}
            onFocus={() => {
              setIsSearchingOnMobile(true);
            }}
            onBlur={() => {
              setIsSearchingOnMobile(false);
            }}
            onQuery={onSearchItemQuery}
          />
          <div className="ml-auto flex items-center">
            <ProfileNavItem className="flex-shrink-0" />
          </div>
        </div>

        <Link className="mr-4 mb-8 lg:mb-0 hidden lg:inline" to={Routes.home()}>
          <Logo />
        </Link>

        <SearchInput
          className="hidden lg:block"
          placeholder="Search"
          theme="dark"
          size="sm"
          onItemSelected={onSearchItemSelected}
          onQuery={onSearchItemQuery}
        />

        <nav className="hidden lg:flex flex-col items-center lg:flex-row lg:ml-auto">
          <span className="mt-8 lg:mt-0 flex items-center">
            <RouteLink className="mr-6" kind="text-only" to={Routes.discover()}>
              Discover
            </RouteLink>
            <RouteLink className="mr-6" kind="text-only" to={Routes.activity()}>
              Feed
            </RouteLink>
            <RouteLink className="mr-6" kind="text-only" to={Routes.trade()}>
              Trade
            </RouteLink>
            {!!currentUser && (
              <RouteLink className="mr-6 hidden lg:inline" kind="text-only" to={Routes.wallet()}>
                Wallet
              </RouteLink>
            )}
            <ExternalLink className="mr-6" kind="text-only" href={mailToAttr}>
              <IoHelpCircleOutline className="h-7 w-7" />
            </ExternalLink>
            {!!currentUser && (
              <>
                <RouteLink className="mr-6 lg:inline" kind="text-only" to={Routes.settingsProfile()}>
                  <IoSettingsOutline className="h-6 w-6" />
                </RouteLink>
                <div className={classNames(notificationCountsSpacing, 'relative')}>
                  {notificationsCount > 0 && (
                    <span className="absolute -top-2 -right-1 flex items-center justify-center text-red font-bold text-sm px-1">
                      {notificationsCount > 99 ? '99+' : notificationsCount}
                    </span>
                  )}
                  <RouteLink kind="text-only" className="mt-2" to={Routes.notifications()} aria-label="notifications">
                    <IoNotificationsOutline className="h-6 w-6" />
                  </RouteLink>
                </div>
              </>
            )}
            <ProfileNavItem className="lg:mr-6" />
          </span>
          {currentUser ? (
            <span className="mt-12 lg:mt-0">
              {!!(currentUser?.ProfileEntryResponse && currentUser?.isProjectOwner) ? (
                <RouteLink
                  kind="btn-secondary"
                  size="sm"
                  shape="rounded"
                  to={Routes.fund(currentUser.ProfileEntryResponse?.Username)}
                >
                  My Project
                </RouteLink>
              ) : (
                <RouteLink
                  kind="btn-secondary"
                  size="sm"
                  shape="rounded"
                  to={
                    currentUser?.ProfileEntryResponse
                      ? Routes.profile(currentUser.ProfileEntryResponse.Username)
                      : Routes.settingsProfile()
                  }
                >
                  My Profile
                </RouteLink>
              )}
            </span>
          ) : (
            <StartProjectButton buttontype="createAccount" size="sm" shape="rounded" />
          )}
        </nav>
      </div>
    </div>
  );
}
