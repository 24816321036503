const MONTHS = Object.freeze(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);

export const ONE_DAY_MILLIS = 86400000;
export const ONE_HOUR_MILLIS = ONE_DAY_MILLIS / 24;
export const ONE_MINUTE_MILLIS = ONE_HOUR_MILLIS / 60;
export const ONE_SECOND_MILLIS = ONE_MINUTE_MILLIS / 60;

export function getMonthName(d: Date) {
  return MONTHS[d.getMonth()];
}

export function formatDateSimple(date: Date) {
  const pad = (v: string) => (v.length === 1 ? `0${v}` : v);
  const month = pad((date.getMonth() + 1).toString());
  const day = pad(date.getDate().toString());
  const year = date.getFullYear().toString();

  return `${year}-${month}-${day}`;
}

export function formatDateSimpleStr(dateStr: string): string {
  const date = new Date(dateStr);
  return formatDateSimple(date);
}

export function convertTimestampToDateFormat(timestamp: number): string {
  const pad = (v: number) => (v.toString().length === 1 ? `0${v}` : v);
  const timestampMs = timestamp.toString().substring(0, 13);
  const timestampDate = new Date(parseInt(timestampMs));
  const day = timestampDate.getDay();
  const date = timestampDate.getDate();
  const month = timestampDate.toLocaleDateString('en-US', { month: 'short' });
  const hour = timestampDate.getHours();
  const minutes = pad(timestampDate.getMinutes());
  const hourFormatted = hour % 12;
  const ampm = hour > 12 ? 'pm' : 'am';
  return `${hourFormatted}:${minutes}${ampm} on ${day} ${month} ${date}`;
}

export function toDateTimePickerFormat(date: Date) {
  const pad = (v: number) => (v.toString().length === 1 ? `0${v}` : v);
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const year = date.getFullYear();
  const hour = pad(date.getHours());
  const minute = pad(date.getMinutes());

  return `${year}-${month}-${day}T${hour}:${minute}`;
}

export function getNumberOfDaysDifference(previousDate: Date, laterDate: Date): number {
  const date1 = new Date(previousDate);
  const date2 = new Date(laterDate);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

function getSecondsDifference(previousDate: Date, laterDate: Date): number {
  return Math.round((laterDate.getTime() - previousDate.getTime()) / 1e3);
}

export function getDateDifferenceStringFromTstampNanos(previousDateTstampNanos: number, laterDate: Date): string {
  return getDateDifferenceString(new Date(previousDateTstampNanos / 1e6), laterDate, true);
}

export function getDateDifferenceString(previousDate: Date, laterDate: Date, useSmallerUnits: boolean = false): string {
  const diffInDays = getNumberOfDaysDifference(previousDate, laterDate);
  if (diffInDays === 0) {
    if (!useSmallerUnits) {
      return 'Today';
    }
    const secondsDiff = getSecondsDifference(previousDate, laterDate);
    if (secondsDiff > 3600) {
      const diffInHours = Math.floor(secondsDiff / 3600);
      return diffInHours > 1 ? `${diffInHours}h ago` : '1h ago';
    }
    if (secondsDiff > 60) {
      const diffInMinutes = Math.floor(secondsDiff / 60);
      return diffInMinutes > 1 ? `${diffInMinutes}m ago` : '1m ago';
    }
    return secondsDiff > 1 ? `${secondsDiff}s ago` : `1s ago`;
  }
  if (diffInDays > 365) {
    const diffInYears = Math.floor(diffInDays / 365);
    return diffInYears > 1 ? `${diffInYears}y ago` : `1y ago`;
  }
  if (diffInDays > 30) {
    const diffInMonths = Math.floor(diffInDays / 30);
    return diffInMonths > 1 ? `${diffInMonths}mo ago` : `1mo ago`;
  }
  return diffInDays > 1 ? `${diffInDays}d ago` : `1d ago`;
}
