import { DESO_WRAPPED_TOKENS, WrappedToken } from '../constants/TradeConstants';

export function isDesoPublicKey(value: string) {
  return value.startsWith('BC') || value.startsWith('tBC');
}

export function getWrappedAsset(username: string, field: keyof WrappedToken = 'name') {
  return DESO_WRAPPED_TOKENS.find((e: { [x: string]: string }) => e[field].toLowerCase() === username.toLowerCase());
}

export function isWrappedAsset(username?: string, field: keyof WrappedToken = 'name') {
  if (!username) {
    return false;
  }

  return !!getWrappedAsset(username, field);
}

export function getWrappedAssetIcon(wrappedToken: WrappedToken): string {
  return `/images/icon-${wrappedToken.displayName}.png`;
}

export const shortenLongWord = (
  key: string | null,
  endFirstPartAfter = 6,
  startSecondPartAfter = 6,
  separator = '...'
) => {
  if (!key || key.length <= endFirstPartAfter + startSecondPartAfter) {
    return key || '';
  }

  return [key.slice(0, endFirstPartAfter), separator, key.slice(-startSecondPartAfter)].join('');
};
