import axios from 'axios';
import { OPENFUND_URL, DESO_IDENTITY_URL, MEGA_SWAPPER_URL, NODE_URL } from 'constants/AppConstants';
import { Openfund } from 'services/Openfund';
import { Deso } from 'services/Deso';
import { DesoIdentity, IdentityStorage } from 'services/Identity';
import { HeroSwapper } from 'services/HeroSwapper';
import { NodeClient } from 'services/NodeClient';
import { Confetti } from './Confetti';

export const identity = new DesoIdentity(DESO_IDENTITY_URL, new IdentityStorage(window.localStorage));
export const nodeClient = new NodeClient(axios.create({ baseURL: NODE_URL, withCredentials: true }));
export const deso = new Deso(nodeClient, identity);
export const heroswap = new HeroSwapper(axios.create({ baseURL: MEGA_SWAPPER_URL }));
export const openfund = new Openfund(axios.create({ baseURL: OPENFUND_URL }), identity, nodeClient, heroswap, deso);
export const confetti = new Confetti();
