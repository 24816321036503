import { getInteractiveElementClassName, InteractiveElementProps } from 'utils/getInteractiveElementClassName';

export function Button({
  kind = 'btn-primary',
  size = 'md',
  shape = 'square',
  type = 'button',
  disabled = false,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement> & InteractiveElementProps) {
  const { className: extraClasses = '', children } = rest;
  const classes = getInteractiveElementClassName({ kind, size, shape, disabled, extraClasses });
  const props = { ...rest, className: classes };
  return (
    <button type={type} disabled={disabled} {...props}>
      {children}
    </button>
  );
}
