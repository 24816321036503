import React, { createContext, useContext, useEffect, useState } from 'react';

interface LegacyModeContextValue {
  isLegacyView: boolean;
  setLegacyView: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialLegacyModeContextValue: LegacyModeContextValue = {
  isLegacyView: true,
  setLegacyView: () => {},
};

export const LegacyModeContext = createContext<LegacyModeContextValue>(initialLegacyModeContextValue);

export const useLegacyModeContext = () => {
  return useContext(LegacyModeContext);
};

const legacyViewKey = 'legacyView';

export const LegacyModeContextProvider: React.FC = ({ children }) => {
  const [isLegacyView, setLegacyView] = useState<boolean>((localStorage.getItem(legacyViewKey) ?? 'true') === 'true');
  useEffect(() => {
    localStorage.setItem(legacyViewKey, String(isLegacyView));
  }, [isLegacyView]);
  return <LegacyModeContext.Provider value={{ isLegacyView, setLegacyView }}>{children}</LegacyModeContext.Provider>;
};
