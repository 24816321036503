import { Logo } from 'components/core/Logo';

// TODO: this just a super basic placeholder for now.
export function SiteLoader() {
  return (
    <div className="animate-ping w-3/4 lg:w-1/2 mx-auto mt-96">
      <Logo width="20%" />
    </div>
  );
}
