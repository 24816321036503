import { Text } from 'components/core/Text';
import { getErrorMsg } from 'utils/getErrorMsg';

// TODO: just a placeholder, we can put something better if time permits.
interface FullPageErrorProps {
  error?: any;
}
export function FullPageError({ error }: FullPageErrorProps) {
  return (
    <div className="text-center mx-auto h-full flex flex-col justify-center">
      <div className="w-1/4 lg:w-1/3 mx-auto mb-12">
        <img className="" src="/images/image-planet-11.svg" />
      </div>
      <Text className="mb-6 text-4xl font-bold font-sans"> Wow! So they do exist?</Text>
      <Text size="lg" className="mb-6">
        Something seems a little off, but don't worry! We're working on it.
      </Text>
      {error && (
        <Text color="error" className="py-4">
          {getErrorMsg(error)}
        </Text>
      )}
    </div>
  );
}
