import { IS_TESTNET } from 'constants/AppConstants';
import { v4 as uuid } from 'uuid';

interface IdentityUser {
  accessLevel: number;
  accessLevelHmac: string;
  btcDepositAddress: string;
  encryptedSeedHex: string;
  ethDepositAddress: string;
  derivedPublicKeyBase58Check?: string;
  hasExtraText: boolean;
  network: string;
  version: number;
}

interface IdentityLoginPayload {
  users: Record<string, IdentityUser>;
  publicKeyAdded: string;
}

interface IdentitySignDerivedKeyPayload {
  id: string;
  method: string;
  service: string;
  payload: {
    accessSignature: string;
    btcDepositAddress: string;
    derivedJwt: string;
    derivedPublicKeyBase58Check: string;
    derivedSeedHex: string;
    ethDepositAddress: string;
    expirationBlock: number;
    jwt: string;
    messagingKeyName: string;
    messagingKeySignature: string;
    messagingPrivateKey: string;
    messagingPublicKeyBase58Check: string;
    network: string;
    publicKeyBase58Check: string;
    transactionSpendingLimitHex: string;
  };
}

type IdentityLogoutPayload = Pick<IdentityLoginPayload, 'users'>;
export type PublicKey = string;

export declare enum DeSoNetwork {
  mainnet = 'mainnet',
  testnet = 'testnet',
}
export interface DerivedPrivateUserInfo {
  derivedSeedHex: string;
  derivedPublicKeyBase58Check: string;
  publicKeyBase58Check: string;
  btcDepositAddress: string;
  ethDepositAddress: string;
  expirationBlock: number;
  network: DeSoNetwork;
  accessSignature: string;
  jwt: string;
  derivedJwt: string;
  messagingPublicKeyBase58Check: string;
  messagingPrivateKey: string;
  messagingKeyName: string;
  messagingKeySignature: string;
  transactionSpendingLimitHex: string | undefined;
}
export declare enum CreatorCoinLimitOperationString {
  ANY = 'any',
  BUY = 'buy',
  SELL = 'sell',
  TRANSFER = 'transfer',
}
export declare enum DAOCoinLimitOperationString {
  ANY = 'any',
  MINT = 'mint',
  BURN = 'burn',
  DISABLE_MINTING = 'disable_minting',
  UPDATE_TRANSFER_RESTRICTION_STATUS = 'update_transfer_restriction_status',
  TRANSFER = 'transfer',
}
export declare type CoinLimitOperationString = DAOCoinLimitOperationString | CreatorCoinLimitOperationString;
export interface CoinOperationLimitMap<T extends CoinLimitOperationString> {
  [public_key: string]: OperationToCountMap<T>;
}
export declare type OperationToCountMap<T extends LimitOperationString> = {
  [operation in T]?: number;
};
export declare type LimitOperationString =
  | DAOCoinLimitOperationString
  | CreatorCoinLimitOperationString
  | NFTLimitOperationString;
export declare type CreatorCoinOperationLimitMap = CoinOperationLimitMap<CreatorCoinLimitOperationString>;
export declare type DAOCoinOperationLimitMap = CoinOperationLimitMap<DAOCoinLimitOperationString>;
export declare type DAOCoinLimitOrderLimitMap = {
  [buying_public_key: string]: {
    [selling_public_key: string]: number;
  };
};
export declare enum NFTLimitOperationString {
  ANY = 'any',
  UPDATE = 'update',
  BID = 'nft_bid',
  ACCEPT_BID = 'accept_nft_bid',
  TRANSFER = 'transfer',
  BURN = 'burn',
  ACCEPT_TRANSFER = 'accept_nft_transfer',
}
export interface NFTOperationLimitMap {
  [post_hash_hex: string]: {
    [serial_number: number]: OperationToCountMap<NFTLimitOperationString>;
  };
}
export declare enum TransactionType {
  BasicTransfer = 'BASIC_TRANSFER',
  BitcoinExchange = 'BITCOIN_EXCHANGE',
  PrivateMessage = 'PRIVATE_MESSAGE',
  SubmitPost = 'SUBMIT_POST',
  UpdateProfile = 'UPDATE_PROFILE',
  UpdateBitcoinUSDExchangeRate = 'UPDATE_BITCOIN_USD_EXCHANGE_RATE',
  Follow = 'FOLLOW',
  Like = 'LIKE',
  CreatorCoin = 'CREATOR_COIN',
  SwapIdentity = 'SWAP_IDENTITY',
  UpdateGlobalParams = 'UPDATE_GLOBAL_PARAMS',
  CreatorCoinTransfer = 'CREATOR_COIN_TRANSFER',
  CreateNFT = 'CREATE_NFT',
  UpdateNFT = 'UPDATE_NFT',
  AcceptNFTBid = 'ACCEPT_NFT_BID',
  NFTBid = 'NFT_BID',
  NFTTransfer = 'NFT_TRANSFER',
  AcceptNFTTransfer = 'ACCEPT_NFT_TRANSFER',
  BurnNFT = 'BURN_NFT',
  AuthorizeDerivedKey = 'AUTHORIZE_DERIVED_KEY',
  MessagingGroup = 'MESSAGING_GROUP',
  DAOCoin = 'DAO_COIN',
  DAOCoinTransfer = 'DAO_COIN_TRANSFER',
  DAOCoinLimitOrder = 'DAO_COIN_LIMIT_ORDER',
}
export interface TransactionSpendingLimitResponse {
  GlobalDESOLimit: number;
  TransactionCountLimitMap?: {
    [k in TransactionType]?: number;
  };
  CreatorCoinOperationLimitMap?: CreatorCoinOperationLimitMap;
  DAOCoinOperationLimitMap?: DAOCoinOperationLimitMap;
  NFTOperationLimitMap?: NFTOperationLimitMap;
  DAOCoinLimitOrderLimitMap?: DAOCoinLimitOrderLimitMap;
  DerivedKeyMemo?: string;
}

export interface IdentityFeatureSupportResponse {
  hasCookieAccess: boolean;
  hasStorageAccess: boolean;
  hasLocalStorageAccess: boolean;
  browserSupported: boolean;
}

const IDENTITY_SERVICE_NAME = 'identity';
const IDENTITY_METHODS = {
  STORAGE_GRANTED: 'storageGranted',
  INFO: 'info',
  LOGIN: 'login',
  INITIALIZE: 'initialize',
  JWT: 'jwt',
  DERIVE: 'derive',
  SIGN: 'sign',
};

export class IdentityStorage {
  private storage: Storage;
  private lastLoggedInKeyName = 'lastLoggedInPublicKey';
  private identityUsersKeyName = 'identityUsers';

  get lastLoggedInPublicKey(): string | null {
    return this.storage.getItem(this.lastLoggedInKeyName);
  }

  get identityUsers(): Record<string, IdentityUser> | null {
    const maybe = this.storage.getItem(this.identityUsersKeyName);
    return maybe && JSON.parse(maybe);
  }

  set lastLoggedInPublicKey(key: string | null) {
    if (!key) {
      this.storage.removeItem(this.lastLoggedInKeyName);
    } else {
      this.storage.setItem(this.lastLoggedInKeyName, key);
    }
  }

  set identityUsers(users: Record<string, IdentityUser> | null) {
    if (users === null || Object.keys(users).length === 0) {
      this.storage.removeItem(this.identityUsersKeyName);
    } else {
      this.storage.setItem(this.identityUsersKeyName, JSON.stringify(users));
    }
  }

  constructor(storage: Storage) {
    this.storage = storage;
  }
}

interface Deferred {
  resolve: Function;
  reject: Function;
}

export class DesoIdentity {
  private identityUrl: string;
  private identityWindow: Window | null = null;
  private identityIframe: HTMLIFrameElement | null = null;
  private storage: IdentityStorage;
  private iframeRequests: Record<string, Deferred> = {};
  private pendingWindowRequest: Deferred | undefined;
  private isIframeLoaded = false;
  iframeLoadedPromise: Promise<boolean>;
  private accessGranted = false;

  get loggedInPublicKey(): PublicKey {
    return this.storage.lastLoggedInPublicKey ?? '';
  }

  set loggedInPublicKey(key: PublicKey | null) {
    this.storage.lastLoggedInPublicKey = key;
  }

  get storedIdentityUsers(): Record<string, IdentityUser> | null {
    return this.storage.identityUsers;
  }

  get loggedInIdentityUser(): IdentityUser | null {
    const loggedInKey = this.storage.lastLoggedInPublicKey;
    const users = this.storage.identityUsers;

    if (!(loggedInKey && users)) {
      return null;
    }

    return users[loggedInKey];
  }

  constructor(identityUrl: string, storage: IdentityStorage) {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', this.messageListener);
    }
    this.identityUrl = identityUrl;
    this.storage = storage;
    this.iframeLoadedPromise = this.initializeIframe();
  }

  initializeIframe = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', `${this.identityUrl}/embed?v=2`);
      iframe.setAttribute('id', 'identity');
      iframe.style.width = '100%';
      iframe.style.height = '100vh';
      iframe.style.position = 'fixed';
      iframe.style.zIndex = '1000';
      iframe.style.display = 'none';
      iframe.style.left = '0';
      iframe.style.right = '0';
      iframe.style.top = '0';
      iframe.addEventListener('error', reject);
      iframe.addEventListener('load', () => {
        this.isIframeLoaded = true;
        if (this.storedIdentityUsers) {
          resolve(this.guardFeatureSupport());
        } else {
          resolve(true);
        }
      });

      const body = document.getElementsByTagName('body')[0];
      body!.appendChild(iframe);

      this.identityIframe = iframe;
    });
  };

  /**
   * @returns The public key for the newly logged in user.
   */
  startLoginFlow = (): Promise<PublicKey> => {
    if (!this.accessGranted) {
      return this.guardFeatureSupport().then((accessGranted) => {
        return new Promise((resolve, reject) => {
          this.pendingWindowRequest = { resolve, reject };
          this.openIdentityWindow('log-in?accessLevelRequest=4&getFreeDeso=true');
        });
      });
    }

    return new Promise((resolve, reject) => {
      this.pendingWindowRequest = { resolve, reject };
      this.openIdentityWindow('log-in?accessLevelRequest=4&getFreeDeso=true');
    });
  };

  /**
   * @returns A list of public keys for alternate users we've stored previously.
   * null if there are no stored users.
   */
  startLogoutFlow = (): Promise<PublicKey[] | null> => {
    const loggedInKey = this.loggedInPublicKey;
    if (!loggedInKey) {
      throw new Error('Attempting to logout but there are is not a logged in user');
    }

    return new Promise((resolve, reject) => {
      this.pendingWindowRequest = { resolve, reject };
      this.openIdentityWindow(`logout?publicKey=${loggedInKey}`);
    });
  };

  startGetDESOFlow = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.pendingWindowRequest = { resolve, reject };
      this.openIdentityWindow(`get-deso?publicKey=${this.loggedInPublicKey}&getFreeDeso=true`);
    });
  };

  verifyPhoneNumber(): Promise<boolean> {
    const loggedInKey = this.loggedInPublicKey;
    if (!loggedInKey) {
      throw new Error('Attempting to verify phone number but there is not a logged in user');
    }

    return new Promise((resolve, reject) => {
      this.pendingWindowRequest = { resolve, reject };
      this.openIdentityWindow(`verify-phone-number?public_key=${loggedInKey}`);
    });
  }

  signDerivedKey(
    derivedPublicKey: PublicKey,
    transactionSpendingLimitResponse: TransactionSpendingLimitResponse,
    expirationDays?: number
  ): Promise<IdentitySignDerivedKeyPayload> {
    return new Promise((resolve, reject) => {
      if (!this.loggedInPublicKey) {
        reject(new Error('Attempted to sign derived key but there is not a logged in user'));
        return;
      }
      this.pendingWindowRequest = { resolve, reject };
      this.openIdentityWindow(
        `derive?derivedPublicKey=${derivedPublicKey}&publicKey=${
          this.loggedInPublicKey
        }&transactionSpendingLimitResponse=${encodeURIComponent(
          JSON.stringify(transactionSpendingLimitResponse)
        )}&expirationDays=${expirationDays}`
      );
    });
  }

  getJWT = (): Promise<string> => {
    const user = this.loggedInIdentityUser;

    if (!user) {
      throw new Error('Attempted to retrieve jwt but there is not a logged in user.');
    }
    const { accessLevel, accessLevelHmac, encryptedSeedHex, derivedPublicKeyBase58Check } = user;
    return this.sendIframeMessage(IDENTITY_METHODS.JWT, {
      accessLevel,
      accessLevelHmac,
      encryptedSeedHex,
      derivedPublicKeyBase58Check,
    }).then(({ jwt, approvalRequired }: any) => {
      if (approvalRequired) {
        throw new Error(
          `Deso identity could not generate a secure jwt token. Your session may have expired and you'll need to log out and log back in. If you are using Brave Browser, \nthis can typically be resolved by disabling shields for ${window.origin}`
        );
      }
      return jwt as string;
    });
  };

  signTx = (transactionHex: string, signerKey?: string): Promise<string> => {
    const key = signerKey ?? this.loggedInPublicKey;
    const user = this.storedIdentityUsers?.[key];

    if (!user) {
      throw new Error(
        `Attempted to sign transaction but there is not an identity user matching the signer key: ${key}`
      );
    }

    const { accessLevel, accessLevelHmac, encryptedSeedHex, derivedPublicKeyBase58Check } = user;
    return this.sendIframeMessage(IDENTITY_METHODS.SIGN, {
      accessLevel,
      accessLevelHmac,
      encryptedSeedHex,
      transactionHex,
      derivedPublicKeyBase58Check,
    }).then((payload: any) => {
      if (payload.approvalRequired) {
        // NOTE: In the case of openfund, we request access level 4 (the highest level), so the assumption here
        // is that the reason we would ever see the approval required response is due to an expired seed hex.
        throw new Error('DeSo identity signing privileges have expired. Try logging out and logging back in.');
      } else if (payload.signedTransactionHex) {
        return payload.signedTransactionHex;
      } else {
        throw new Error(`Unable to sign transaction with transaction hex: ${transactionHex}`);
      }
    });
  };

  // TODO: we also need to add a guard for browserSupported
  private guardFeatureSupport = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getFeatureSupportInfo().then((payload) => {
        if (this.identityIframe && (!payload.hasStorageAccess || !payload.browserSupported)) {
          // the deso identity window uses an experimental api
          // (requestStorageAccess) that has spotty support across browsers.
          // Here we check if the request for storage access performed by
          // identity will fail with an uncaught error. If so, we just assume
          // the user's browser is not supported.
          if (payload.browserSupported && typeof document.requestStorageAccess !== 'function') {
            return resolve(false);
          }

          this.iframeRequests[IDENTITY_METHODS.STORAGE_GRANTED] = { resolve, reject };
          this.identityIframe.style.display = 'block';
        } else {
          resolve(true);
        }
      });
    }).then((accessGranted) => {
      if (accessGranted && this.identityIframe) {
        this.identityIframe.style.display = 'none';
        this.accessGranted = true;
      }

      return Boolean(accessGranted);
    });
  };

  getFeatureSupportInfo = () => {
    return this.sendIframeMessage('info') as Promise<IdentityFeatureSupportResponse>;
  };

  private sendIframeMessage = (method: string, payload: Record<string, any> = {}) => {
    if (!this.isIframeLoaded) {
      throw new Error(`Attempted to send '${method}' message to identity iframe but iframe is not loaded.`);
    }

    return new Promise((resolve, reject) => {
      if (!this.identityIframe?.contentWindow) {
        reject(new Error(`Attempted to send '${method}' message to identity iframe but iframe is not loaded.`));
      }
      const message = {
        id: uuid(),
        method,
        payload,
        service: IDENTITY_SERVICE_NAME,
      };
      this.identityIframe?.contentWindow?.postMessage(message, this.identityUrl as WindowPostMessageOptions);
      this.iframeRequests[message.id] = { resolve, reject };
    });
  };

  private openIdentityWindow(path: string) {
    if (this.identityWindow) {
      this.identityWindow.close();
    }

    const h = 1000;
    const w = 800;
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    const cleanPath = path.startsWith('/') ? path.substring(1) : path;

    this.identityWindow = window.open(
      `${this.identityUrl}/${IS_TESTNET ? `${cleanPath}&testnet=true` : cleanPath}`,
      undefined,
      `toolbar=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  }

  private messageListener = (ev: MessageEvent<any>) => {
    if (ev.origin !== this.identityUrl || ev.data.service !== IDENTITY_SERVICE_NAME || ev.source === null) {
      return;
    }

    if (ev.source === this.identityWindow) {
      this.identityWindowListener(ev);
    }

    if (ev.source === this.identityIframe?.contentWindow) {
      this.identityIframeListener(ev);
    }
  };

  private identityIframeListener = ({ source, data }: MessageEvent<any>) => {
    switch (data.method) {
      case IDENTITY_METHODS.INITIALIZE:
        this.initialize(source!, data);
        break;
      case IDENTITY_METHODS.STORAGE_GRANTED:
        this.iframeRequests[IDENTITY_METHODS.STORAGE_GRANTED].resolve(true);
        delete this.iframeRequests[IDENTITY_METHODS.STORAGE_GRANTED];
        break;
      default:
        const request = this.iframeRequests[data.id];

        if (!request) {
          throw new Error(`Received identity response for request id ${data.id} but there is no matching request`);
        }

        request.resolve(data.payload);
        delete this.iframeRequests[data.id];
    }
  };

  private identityWindowListener = ({ source, data }: MessageEvent<any>) => {
    switch (data.method) {
      case IDENTITY_METHODS.INITIALIZE:
        this.initialize(source!, data);
        break;
      case IDENTITY_METHODS.LOGIN:
        if (!this.pendingWindowRequest) {
          throw new Error('There was an identity login method response but no pending request.');
        }

        if (typeof data.payload.phoneNumberSuccess === 'boolean' && typeof data.payload.signedUp === 'undefined') {
          this.pendingWindowRequest.resolve(data.payload.phoneNumberSuccess);
        } else if (data.payload.publicKeyAdded) {
          this.storeUser(data.payload);
          const publicKeyStored = this.loggedInPublicKey;
          if (data.payload.publicKeyAdded !== publicKeyStored) {
            this.pendingWindowRequest.reject(new Error('The logged in user data could not be stored.'));
          } else {
            this.pendingWindowRequest.resolve(publicKeyStored);
          }
        } else {
          const publicKeyToRemove = this.loggedInPublicKey;
          if (!publicKeyToRemove) {
            this.pendingWindowRequest.reject(new Error('Attempting to logout but there is no logged in public key.'));
          }
          try {
            this.purgeUser(publicKeyToRemove, data.payload);
          } catch (e) {
            this.pendingWindowRequest.reject(e);
          }

          this.storage.lastLoggedInPublicKey = null;
          this.pendingWindowRequest.resolve(null);
        }
        this.pendingWindowRequest = undefined;
        this.identityWindow?.close();
        break;
      case IDENTITY_METHODS.DERIVE:
        if (!this.pendingWindowRequest) {
          throw new Error('There was an identity login method response but no pending request.');
        }
        this.identityWindow?.close();
        this.pendingWindowRequest?.resolve(data);
    }
  };

  private initialize(source: MessageEventSource, data: any) {
    source?.postMessage(
      {
        id: data.id,
        service: IDENTITY_SERVICE_NAME,
        payload: {},
      },
      this.identityUrl as WindowPostMessageOptions
    );
  }

  private storeUser({ publicKeyAdded, users }: IdentityLoginPayload) {
    this.storage.lastLoggedInPublicKey = publicKeyAdded;
    const storedUsers = this.storage.identityUsers;
    const newUsers = { [publicKeyAdded]: users[publicKeyAdded] };
    this.storage.identityUsers = { ...storedUsers, ...newUsers };
  }

  private purgeUser(publicKeyRemoved: string, { users }: IdentityLogoutPayload) {
    const storedUsers = this.storage.identityUsers;

    if (storedUsers === null || !storedUsers[publicKeyRemoved]) {
      throw new Error(`Could not purge data for public key: ${publicKeyRemoved}`);
    }

    delete storedUsers[publicKeyRemoved];
    this.storage.identityUsers = storedUsers;
  }
}
