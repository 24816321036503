import { NavLink, NavLinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import { getInteractiveElementClassName, InteractiveElementProps } from 'utils/getInteractiveElementClassName';

export function RouteLink({
  kind = 'text-only',
  size = 'md',
  shape = 'square',
  ...rest
}: InteractiveElementProps & NavLinkProps) {
  const resolved = useResolvedPath(rest.to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const { className: extraClasses = '', children } = rest;
  const classes = getInteractiveElementClassName({ kind, size, shape, extraClasses, disabled: false });
  const props = { ...rest, className: classes };

  return (
    <NavLink {...props} aria-current={match ? 'page' : undefined}>
      {children}
    </NavLink>
  );
}
