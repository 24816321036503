import { Transition } from '@headlessui/react';
import { IoCheckmarkCircleOutline } from '@react-icons/all-files/io5/IoCheckmarkCircleOutline';
import { IoCloseCircleOutline } from '@react-icons/all-files/io5/IoCloseCircleOutline';
import { IoWarningOutline } from '@react-icons/all-files/io5/IoWarningOutline';
import { IoCloseOutline } from '@react-icons/all-files/io5/IoCloseOutline';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { Fragment, useState } from 'react';
import { IoTimeOutline } from '@react-icons/all-files/io5/IoTimeOutline';
import { useIsMounted } from 'hooks/useIsMounted';

/**
 * Global toast component. To render a toast from any component, use useToast hook:
 * @example
 * ```js
 * import { useToast } from 'components/hooks/useToast';
 *
 * function SomeComponent(...) {
 *   const toast = useToast();
 *
 *   return <button onClick={() => {
 *     toast.show({ message: 'you did it!', type: 'success'});
 *   }}>Show Toast</button>
 * }
 * ```
 */
export interface ToastProps {
  id?: string;
  message: string | JSX.Element;
  type: 'success' | 'error' | 'warning' | 'waiting';
  link?: string;
  manualID?: string;
  sticky?: boolean;
}

export type ToastShowProps = Pick<ToastProps, 'message' | 'type' | 'manualID' | 'sticky'>;
export type ToastCloseProps = Pick<ToastProps, 'manualID'>;

export interface ToastShow {
  show: ({ message, type, manualID, sticky }: ToastShowProps) => void;
  removeToastWithManualID: ({ manualID }: ToastCloseProps) => void;
  hasManualID: ({ manualID }: ToastCloseProps) => Promise<boolean>;
}

const COLORS = Object.freeze({
  success: 'green',
  error: 'red',
  warning: 'yellow',
  waiting: 'gray',
});

const TITLES = Object.freeze({
  success: 'Success!',
  error: 'Error!',
  warning: 'Warning!',
  waiting: 'Waiting!',
});

interface ToastComponentProps {
  message: string | JSX.Element;
  type: 'success' | 'error' | 'warning' | 'waiting';
  link?: string;
  sticky?: boolean;
  close: () => void;
}
export function Toast({ message, type, link = '', sticky = true, close }: ToastComponentProps) {
  const [show, setShow] = useState(false);
  const iconClassName = `h-6 w-6 text-${COLORS[type]}`;
  const isMounted = useIsMounted();
  let Icon;

  useEffectOnce(() => {
    if (isMounted.current) {
      setShow(true);
    }
  });

  useEffectOnce(() => {
    if (!sticky) {
      const timerId = window.setTimeout(() => {
        if (isMounted.current) {
          close();
          setShow(false);
        }
      }, 3000);

      return () => {
        clearTimeout(timerId);
      };
    }
  });

  let isWaiting = type === 'waiting';
  switch (type) {
    case 'error':
      Icon = IoCloseCircleOutline;
      break;
    case 'warning':
      Icon = IoWarningOutline;
      break;
    case 'success':
      Icon = IoCheckmarkCircleOutline;
      break;
    case 'waiting':
      Icon = IoTimeOutline;
      break;
  }

  let innerWidgetBody = (
    <>
      <div className="ml-3 w-3/4 flex-1 pt-0.5">
        {!isWaiting && <p className="text-sm font-medium text-gray">{TITLES[type]}</p>}
        <div className="mt-1 text-sm text-gray">{message}</div>
        {isWaiting && (
          <>
            <IoTimeOutline className="animate-spin inline text-green" />
          </>
        )}
      </div>
    </>
  );
  if (link) {
    innerWidgetBody = (
      <a target="_blank" href={link} rel="noreferrer" className="ml-3 flex-1 pt-0.5">
        {innerWidgetBody}
      </a>
    );
  }

  return (
    <>
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end mb-2">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm bg-white dark:bg-gray-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {!isWaiting && <Icon className={iconClassName} aria-hidden="true" />}
                </div>
                {innerWidgetBody}
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white dark:bg-bray-800 rounded-md inline-flex text-gray hover:text-gray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
                    onClick={() => {
                      setShow(false);
                      setTimeout(close, 500);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <IoCloseOutline className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
